import { Box, makeStyles } from '@material-ui/core'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomText from '../../../../shared/CustomText'

const Screen03 = (props) => {
  const { handleScreenReset, userInfo } = props

  const getTextForMessageContent = () => {
    if (userInfo.wish === 'Emploi') {
      return "d'emploi"
    } else if (userInfo.wish === 'Formation') {
      return 'de formation'
    }
  }

  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            <Box mt={2}>
              <CustomTitle position="center">Bravo !</CustomTitle>
              <CustomText position="center">
                Tu as appris à rédiger un C.V ainsi qu'une lettre de motivation,
                et tu t'es renseigné(e) à propos des organismes compétents pour ta
                recherche {getTextForMessageContent()}. Tu es maintenant prêt(e) à
                déposer tes candidatures.
              </CustomText>
            </Box>
            <CustomButton handleNext={handleScreenReset}>
              Déposer des candidatures
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen03

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
}))
