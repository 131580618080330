import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'

import { workQuizz, schoolQuizz } from '../content'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomInterviewDialog from '../../../../shared/CustomInterviewDialog'
import CustomProgress from '../../../../shared/CustomProgress'

const Screen02 = (props) => {
  const {
    userInfo,
    setStep,
    setScreen,
    handleScreenNext,
    trainingQuizz,
    setTrainingQuizz,
    interviewQuizz,
    setInterviewQuizz,
  } = props

  const classes = useStyles()

  const [questions, setQuestions] = useState([])
  const [questionsShuffled, setQuestionsShuffled] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialContent, setDialContent] = useState({ title: '', text: '' })
  const [progress, setProgress] = useState(100)
  const [timeLeft, setTimeLeft] = useState(30)
  const [isPaused, setIsPaused] = useState(false)

  useEffect(() => {
    const tempArr = questions
    tempArr.forEach(({ answers }) => (answers = shuffle(answers)))
    setQuestionsShuffled(tempArr)
  }, [questions])

  useEffect(() => {
    switch (userInfo.wish) {
      case 'Emploi':
        setQuestions(workQuizz)
        break
      case 'Formation':
        setQuestions(schoolQuizz)
        break
      default:
        break
    }
  }, [userInfo.wish])

  useEffect(() => {
    const timer = setInterval(() => {
      if (!interviewQuizz.intro && !isPaused) {
        setProgress((prevProgress) => prevProgress - 10 / 3)
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
      }
    }, 1000)
    if (progress <= 0) {
      clearInterval(timer)
      setTimeLeft(0)
      handleAnswerSubmit(-1)
    }
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, interviewQuizz.intro])

  const shuffle = (array) => {
    var currentIndex = array.length,
      randomIndex

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }

  const handleAnswerSubmit = (idx) => {
    setIsPaused(true)
    const result = questionsShuffled[interviewQuizz.currentQuestion]
    const wishText = getUserWish(userInfo.wish)

    // if (!idx) {

    // }

    if (trainingQuizz.lifes === 1 && idx !== -1 && !result.answers[idx].correct) {
      setTrainingQuizz({
        ...trainingQuizz,
        lifes: trainingQuizz.lifes - 1,
      })
      setDialContent({
        title: 'Dommage',
        text: `Tu n’as pas réussi à décrocher ${wishText}. Mais tu as de la chance, une nouvelle opportunité vient d’arriver sur ton téléphone et tu peux reprendre ta préparation pour ce nouvel entretien`,
      })
      setDialogOpen(true)
    } else if (idx !== -1 && result.answers[idx].correct) {
      setDialContent({
        title: "Félicitations ! Vous ne perdez pas de point d'embauche !",
        text: result.answers[idx].popup,
      })
      setDialogOpen(true)
    } else if (idx !== -1 && !result.answers[idx].correct) {
      if (result.answers[idx].level === 'average') {
        setTrainingQuizz({
          ...trainingQuizz,
          lifes: trainingQuizz.lifes - 1,
        })
        setDialContent({
          title: "Mauvaise réponse, Vous perdez un point d'embauche !",
          text: result.answers[idx].popup,
        })
        setDialogOpen(true)
      }
      if (result.answers[idx].level === 'bad') {
        setTrainingQuizz({
          ...trainingQuizz,
          lifes: trainingQuizz.lifes - 1,
        })
        setDialContent({
          title: "Mauvaise réponse, Vous perdez un point d'embauche !",
          text: result.answers[idx].popup,
        })
        setDialogOpen(true)
      }
    } else if (idx === -1) {
      setTrainingQuizz({
        ...trainingQuizz,
        lifes: trainingQuizz.lifes - 1,
      })
      setDialContent({
        title: "Vous n'avez pas repondu !",
        text: "Vous perdez un point d'embauche.",
      })

      setDialogOpen(true)
    }
  }

  const restartJobQuizz = () => {
    sessionStorage.setItem('currentStep', 3)
    setStep(5)
    setScreen(0)
    setTrainingQuizz({
      currentQuestion: 0,
      totalScore: 0,
      lifes: 0,
    })
    setInterviewQuizz({
      currentQuestion: 0,
      intro: false,
    })
  }

  const getPrefixLetter = (v) => {
    switch (v) {
      case 0:
        return 'a'
      case 1:
        return 'b'
      case 2:
        return 'c'
      default:
        break
    }
  }

  const getUserWish = (value) => {
    switch (value) {
      case 'Emploi':
        return 'ce poste'
      case 'Formation':
        return 'une place pour cette formation'
      default:
        return 'cette oppotunité'
    }
  }

  const handleQuizzNext = () => {
    if (interviewQuizz.currentQuestion === questionsShuffled.length - 1) {
      setInterviewQuizz({
        ...interviewQuizz,
        currentQuestion: interviewQuizz.currentQuestion + 1,
      })
      handleScreenNext()
    } else {
      setInterviewQuizz({
        ...interviewQuizz,
        currentQuestion: interviewQuizz.currentQuestion + 1,
      })
      setIsPaused(false)
      setProgress(100)
      setTimeLeft(30)
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <CustomInterviewDialog
        dialogOpen={dialogOpen}
        setIsPaused={setIsPaused}
        handleDialogClose={handleDialogClose}
        questions={questions}
        currentQuestionIndex={interviewQuizz.currentQuestion}
        trainingQuizz={trainingQuizz}
        restartJobQuizz={restartJobQuizz}
        dialContent={dialContent}
        handleQuizzNext={handleQuizzNext}
      />

      <div className={classes.root}>
        <div>
          <CustomTitle position="center">
            <span>{interviewQuizz.currentQuestion + 1}. </span>
            {questionsShuffled.length !== 0 &&
              questionsShuffled[interviewQuizz.currentQuestion].question}
          </CustomTitle>
          <Grid item className={classes.responseWrapper}>
            <Typography style={{ marginBottom: '-20px' }}>
              Points d'embauche restant : {trainingQuizz.lifes}
            </Typography>
            <CustomProgress timeLeft={timeLeft} progress={progress} />
            <Grid container spacing={3} className={classes.reponseWrapper}>
              {questionsShuffled.length !== 0 &&
                questionsShuffled[interviewQuizz.currentQuestion].answers.map(
                  ({ id, content }, idx) => (
                    <Grid item xs={12} key={id}>
                      <Card
                        elevation={2}
                        className={classes.card}
                        onClick={() => handleAnswerSubmit(idx)}
                      >
                        <CardActionArea>
                          <CardContent>
                            <Typography
                              style={{ padding: '0.5rem' }}
                              variant="h6"
                              className={classes.quizzResponse}
                            >
                              <span>{getPrefixLetter(idx)}.</span>
                              {content}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )
                )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  card: {
    '&.selected': {
      backgroundColor: '#B1B1B1',
    },
  },
  validAnswerBtnContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  validAnswerBtn: {
    textTransform: 'capitalize',
  },
  responseWrapper: {
    padding: '0 1.5rem',
  },
  quizzTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(6),
  },
  quizzQuestion: {
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      marginRight: theme.spacing(3),
    },
  },
  highlightSpan: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  quizzResponse: {
    '& > span': {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 700,
      marginRight: theme.spacing(3),
    },
  },
  questionPaper: {
    padding: theme.spacing(3),
  },
  reponseWrapper: {
    marginTop: theme.spacing(3),
  },
  reponsePaper: {
    padding: theme.spacing(3),
  },
  nextStepBtn: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  nextStepBtnIcon: {
    marginLeft: theme.spacing(3),
  },
}))
