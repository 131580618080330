import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import FsLightbox from 'fslightbox-react'
import CardMedia from '@material-ui/core/CardMedia'

import { workBodyGood, schoolBodyGood } from '../../../../images'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'

const Screen02 = (props) => {
  const { isCorrect, handleScreenNext, userWish, toggler, setToggler } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            {isCorrect === 1 && (
              <>
                <CustomTitle position="center">
                  Bravo, tu as choisi le corps de lettre idéal !
                </CustomTitle>
                <CustomText position="center">
                  C'est ça ! Tous les éléments du corps de la lettre sont bien
                  disposés. Au début comme à la fin, il faut toujours mettre une
                  formule de politesse, ici "Monsieur Madame". Il faut bien se
                  mettre en valeur, bien se présenter, montrer ses qualités sans
                  mentir, ni en faire trop car cela sera remarqué.
                </CustomText>
              </>
            )}
            {isCorrect === 2 && (
              <>
                <CustomTitle position="center">
                  Aïe, ce n'est pas la bonne réponse
                </CustomTitle>
                <CustomText position="center">
                  Ouch, c’est un peu brut de décoffrage tout cela non ? Il ne
                  faut pas mentir, mais trop d'honnêteté peut nuire au contenu
                  de la lettre, surtout si votre motivation n’est pas incroyable
                  ; cela va se ressentir ! Ne mettez pas en avant vos
                  expériences de façon négative, c’est un mauvais signe pour
                  l’employeur.
                </CustomText>
                <CustomText position="center">
                  Voici le modèle idéal :
                </CustomText>
              </>
            )}
            {isCorrect === 3 && (
              <>
                <CustomTitle position="center">
                  Aïe, ce n'est pas la bonne réponse
                </CustomTitle>
                <CustomText position="center">
                  Des fautes partout, des mots qui ne veulent rien dire, la mise
                  en forme est littéralement explosée, sans compter les
                  changements de couleur… C’était le pire choix possible à tout
                  point de vue !
                </CustomText>
                <CustomText position="center">
                  Voici le modèle idéal :
                </CustomText>
              </>
            )}

            <Grid
              container
              justify="center"
              style={{ marginBottom: '2rem' }}
            ></Grid>

            <Grid container justify="center" style={{ marginBottom: '2rem' }}>
              <Grid item xs={12}>
                <Card className={classes.itemContainer}>
                  {userWish === 'Emploi' && (
                    <CardMedia
                      className={classes.media}
                      image={workBodyGood}
                      title="curriculum-vitae"
                      onClick={() => setToggler(!toggler)}
                    />
                  )}
                  {userWish === 'Formation' && (
                    <CardMedia
                      className={classes.media}
                      image={schoolBodyGood}
                      title="curriculum-vitae"
                      onClick={() => setToggler(!toggler)}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
            {userWish === 'Emploi' && (
              <FsLightbox toggler={toggler} sources={[workBodyGood]} />
            )}
            {userWish === 'Formation' && (
              <FsLightbox toggler={toggler} sources={[schoolBodyGood]} />
            )}
            <CustomButton handleNext={handleScreenNext}>
              Passer à la dernière étape
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  media: {
    border: '2px solid #00000025',
    cursor: 'zoom-in',
    height: '100%',
  },
  itemContainer: {
    width: '100%',
    height: '300px',
  },
}))
