import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const CustomText = (props) => {
  const { children, position } = props

  const classes = useStyles()

  return (
    <Typography align={position ? position : 'left'} className={classes.root}>
      {children}
    </Typography>
  )
}

export default CustomText

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '16px',
    color: theme.custom.textColor,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))
