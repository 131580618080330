import { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { choiceCvData } from '../content'
import { topFunction } from '../../../../utils'

import Grid from '@material-ui/core/Grid'
import FsLightbox from 'fslightbox-react'
import CardMedia from '@material-ui/core/CardMedia'

import { cvGood, cvAverage, cvBad } from '../../../../images'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import ContentWrapper from '../../../../shared/ContentWrapper'

const Screen01 = (props) => {
  const { selectedIdx, setSelectedIdx, handleChoiceClick } = props

  const classes = useStyles()

  const [toogler, setToogler] = useState(false)
  const [pictureLink, setPictureLink] = useState('')

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  const getCurrentImage = (value) => {
    switch (value) {
      case 'good':
        return cvGood
      case 'average':
        return cvAverage
      case 'bad':
        return cvBad
      default:
        break
    }
  }

  const handlePreviewClick = (image) => {
    setPictureLink(image)
    setToogler(!toogler)
  }

  return (
    <>
      <CustomPaper>
        <ContentWrapper>
          <CustomTitle position="center">
            Pour cette étape, il te faudra sélectionner le modèle de CV qui te
            semble le plus adapté :
          </CustomTitle>
          <Grid
            container
            justify="space-around"
            direction="column"
            style={{ marginBottom: '1rem' }}
          >
            {choiceCvData.map(({ image }, idx) => (
              <Box
                style={{
                  cursor: 'pointer',
                }}
                mb={4}
                key={idx}
                onClick={() => setSelectedIdx(idx)}
              >
                <Grid
                  className={`${classes.itemContainer} ${
                    selectedIdx === idx && classes.isSelected
                  }`}
                >
                  <div
                    className={`${classes.buttonWrapper} ${
                      selectedIdx === idx && 'isSelected'
                    }`}
                  >
                    <Grid container direction="column">
                      <button
                        type="button"
                        className={classes.button}
                        onClick={() =>
                          handlePreviewClick(getCurrentImage(image))
                        }
                      >
                        Aperçu
                      </button>
                      <button
                        type="button"
                        className={classes.button}
                        onClick={() => setSelectedIdx(idx)}
                      >
                        Sélectionner
                      </button>
                    </Grid>
                  </div>
                  <CardMedia
                    className={classes.media}
                    image={getCurrentImage(image)}
                    title={`curriculum-vitae-${idx}`}
                  />
                </Grid>
              </Box>
            ))}
          </Grid>
          <CustomButton handleNext={handleChoiceClick}>
            Valider ce choix
          </CustomButton>
        </ContentWrapper>
      </CustomPaper>
      <FsLightbox toggler={toogler} sources={[pictureLink]} />
    </>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: '100%',
  },
  itemContainer: {
    border: '2px solid #00000025',
    height: '100vh',
    maxHeight: '300px',
    width: '100%',
    position: 'relative',
  },
  starWrapper: {
    backgroundColor: '#efefef',
    padding: '0.15rem',
    margin: '0.5rem',
    borderRadius: '50%',
    border: '2px solid grey',
    position: 'absolute',
    right: 0,
    '&.isSelected': {
      borderColor: '#FFA41C',
    },
  },
  selectionIcon: {
    fontSize: '2.2rem',
    marginBottom: '-3px',
    color: theme.palette.common.black,
    '&.isSelected': {
      color: '#FFA41C',
    },
  },
  buttonWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.6)',
    padding: '1.5rem 3rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.25rem',
  },
  button: {
    color: theme.palette.common.white,
    border: 'none',
    borderRadius: '0.25rem',
    background: theme.custom.answerColorHover,
    padding: '0.75rem 2rem',
    margin: '0.5rem 0',
    fontSize: '1rem',
    cursor: 'pointer',
    outline: 'none',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  isSelected: {
    border: '2px solid #c946e3',
    boxShadow:
      '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0.2), -5px 5px 15px rgba(0, 0, 0, 0.2), 5px -5px 15px rgba(0, 0, 0, 0.2)',
  },
}))
