import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { choiceCvData } from './content'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'
import CustomAgeDialog from '../../../shared/CustomAgeDialog'

const Step02 = (props) => {
  const { screen, setScreen, handleStepNext } = props

  const classes = useStyles()

  const [isCorrect, setIsCorrect] = useState(0)
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [toggler, setToggler] = useState(false)
  const [open, setOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [errorTitle, setErrorTitle] = useState('')

  const handleScreenNext = () => {
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenBack = () => {
    setScreen((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentScreen', screen - 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  const handleChoiceClick = () => {
    if (selectedIdx === -1) {
      setErrorTitle('Un CV doit être sélectionné')
      setErrorText('Il faut renseigner ton choix afin de pouvoir continuer')
      setOpen(true)
    } else if (choiceCvData[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (choiceCvData[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (choiceCvData[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  return (
    <>
      <CustomAgeDialog
        open={open}
        setOpen={setOpen}
        errorTitle={errorTitle}
        errorText={errorText}
      />
      <Container className={classes.container}>
        <Step02Stepper
          handleScreenNext={handleScreenNext}
          handleScreenBack={handleScreenBack}
          screen={screen}
        >
          <Step02Screen>
            <Grid item className={classes.stepContainer}>
              <Screen01
                selectedIdx={selectedIdx}
                setSelectedIdx={setSelectedIdx}
                handleChoiceClick={handleChoiceClick}
              />
            </Grid>
          </Step02Screen>
          <Step02Screen>
            <Grid item className={classes.stepContainer}>
              <Screen02
                toggler={toggler}
                isCorrect={isCorrect}
                setToggler={setToggler}
                handleScreenReset={handleScreenReset}
              />
            </Grid>
          </Step02Screen>
        </Step02Stepper>
      </Container>
    </>
  )
}

const Step02Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <>{currentChild}</>
}

const Step02Screen = ({ children }) => {
  return <>{children}</>
}

export default Step02

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: '100vh',
  },
  stepContainer: {
    padding: theme.spacing(6),
  },
  stepper: {
    marginBottom: theme.spacing(3),
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiStepIcon-text': {
      fill: '#286a96',
    },
  },
}))
