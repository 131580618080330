import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Header from '../components/home/Header'
import Step01 from '../components/steps/Step01'
import Step02 from '../components/steps/Step02'
import Step03 from '../components/steps/Step03'
import Step04 from '../components/steps/Step04'
import Step05 from '../components/steps/Step05'
import Step06 from '../components/steps/Step06'
import Step07 from '../components/steps/Step07'
import Footer from '../components/home/Footer'

const Home = (props) => {
  const { toogleTheme, setToogleTheme } = props
  const classes = useStyles()

  const switchThemeHandler = () => {
    setToogleTheme(!toogleTheme)
  }

  const stepFromStore = JSON.parse(sessionStorage.getItem('currentStep'))
  const screenFromStore = JSON.parse(sessionStorage.getItem('currentScreen'))
  const userInfoFromStore = JSON.parse(sessionStorage.getItem('userInfo'))
  const trainingQuizzStatus = JSON.parse(
    sessionStorage.getItem('trainingQuizz')
  )
  const interviewQuizzStatus = JSON.parse(
    sessionStorage.getItem('interviewQuizz')
  )

  const [step, setStep] = useState(stepFromStore ? stepFromStore : 0)
  const [screen, setScreen] = useState(screenFromStore ? screenFromStore : 0)
  const [userInfo, setUserInfo] = useState(
    userInfoFromStore
      ? userInfoFromStore
      : {
          name: '',
          age: -1,
          wish: '',
          myAvatar: Math.floor(Math.random() * 5000),
          friendAvatar: Math.floor(Math.random() * 5000),
          bossAvatar: Math.floor(Math.random() * 5000),
        }
  )
  const [trainingQuizz, setTrainingQuizz] = useState(
    trainingQuizzStatus
      ? trainingQuizzStatus
      : {
          currentQuestion: 0,
          totalScore: 0,
          lifes: 0,
        }
  )

  const [interviewQuizz, setInterviewQuizz] = useState(
    interviewQuizzStatus
      ? interviewQuizzStatus
      : {
          currentQuestion: 0,
        }
  )

  useEffect(() => {
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    sessionStorage.setItem('trainingQuizz', JSON.stringify(trainingQuizz))
    sessionStorage.setItem('interviewQuizz', JSON.stringify(interviewQuizz))
  }, [trainingQuizz, interviewQuizz, userInfo])

  const handleStepNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentStep', step + 1)
  }

  const handleStepBack = () => {
    setStep((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentStep', step - 1)
  }

  const resetSeriousGame = () => {
    sessionStorage.setItem(
      'userInfo',
      JSON.stringify({
        name: '',
        age: -1,
        wish: '',
        myAvatar: Math.floor(Math.random() * 5000),
        friendAvatar: Math.floor(Math.random() * 5000),
        bossAvatar: Math.floor(Math.random() * 5000),
      })
    )
    sessionStorage.setItem(
      'trainingQuizz',
      JSON.stringify({
        currentQuestion: 0,
        totalScore: 0,
        lifes: 0,
      })
    )
    sessionStorage.setItem(
      'interviewQuizz',
      JSON.stringify({
        currentQuestion: 0,
      })
    )
    sessionStorage.setItem('currentScreen', '0')
    sessionStorage.setItem('currentStep', '0')
    window.location.reload()
  }

  return (
    <>
      <Header
        toogleTheme={toogleTheme}
        switchThemeHandler={switchThemeHandler}
      />

      <Grid item className={classes.root}>
        <Container maxWidth="md">
          <MiloStepper
            style={{ display: 'flex', alignItems: 'center' }}
            handleStepNext={handleStepNext}
            handleStepBack={handleStepBack}
            step={step}
          >
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step01
                  screen={screen}
                  userInfo={userInfo}
                  setScreen={setScreen}
                  setUserInfo={setUserInfo}
                  handleStepNext={handleStepNext}
                />
              </Grid>
            </MiloStep>
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step02
                  screen={screen}
                  setScreen={setScreen}
                  handleStepNext={handleStepNext}
                />
              </Grid>
            </MiloStep>
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step03
                  screen={screen}
                  userInfo={userInfo}
                  setScreen={setScreen}
                  handleStepNext={handleStepNext}
                />
              </Grid>
            </MiloStep>
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step04
                  screen={screen}
                  userInfo={userInfo}
                  setScreen={setScreen}
                  handleStepNext={handleStepNext}
                />
              </Grid>
            </MiloStep>
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step05
                  screen={screen}
                  userInfo={userInfo}
                  setScreen={setScreen}
                  handleStepNext={handleStepNext}
                />
              </Grid>
            </MiloStep>
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step06
                  screen={screen}
                  userInfo={userInfo}
                  setScreen={setScreen}
                  handleStepNext={handleStepNext}
                  trainingQuizz={trainingQuizz}
                  setTrainingQuizz={setTrainingQuizz}
                />
              </Grid>
            </MiloStep>
            <MiloStep>
              <Grid item className={classes.stepContainer}>
                <Step07
                  screen={screen}
                  setStep={setStep}
                  userInfo={userInfo}
                  setScreen={setScreen}
                  handleStepNext={handleStepNext}
                  trainingQuizz={trainingQuizz}
                  setTrainingQuizz={setTrainingQuizz}
                  interviewQuizz={interviewQuizz}
                  setInterviewQuizz={setInterviewQuizz}
                  resetSeriousGame={resetSeriousGame}
                />
              </Grid>
            </MiloStep>
          </MiloStepper>
        </Container>
      </Grid>
      <Footer />
    </>
  )
}

const MiloStepper = (props) => {
  const { children, step } = props
  const childrenArray = React.Children.toArray(children)
  const screenFromStore = sessionStorage.getItem('currentScreen')
  const stepFromStore = sessionStorage.getItem('currentStep')

  useEffect(() => {
    if (!stepFromStore) {
      sessionStorage.setItem('currentStep', JSON.stringify(0))
    }
    if (!screenFromStore) {
      sessionStorage.setItem('currentScreen', JSON.stringify(0))
    }
  })

  const currentChild = childrenArray[step]

  return <Grid item>{currentChild}</Grid>
}

const MiloStep = ({ children }) => {
  return <>{children}</>
}

export default Home

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.custom.appBgColor,
  },
  themeIcon: {
    color: theme.custom.titleColor,
  },
}))
