import { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import CustomAgeDialog from '../../../../shared/CustomAgeDialog'
import CustomButton from '../../../../shared/CustomButton'
import CustomTitle from '../../../../shared/CustomTitle'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomPaper from '../../../../shared/CustomPaper'

const Screen02 = (props) => {
  const { handleScreenNext, userInfo, setUserInfo } = props

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [errorTitle, setErrorTitle] = useState('')
  const [screenToggler, setScreenToggler] = useState(true)

  const handleUserNameChange = (e) => {
    const value = e.target.value
    setUserInfo({
      ...userInfo,
      name: value.trim(),
    })
  }

  const handleUserAgeChange = (e) => {
    setUserInfo({
      ...userInfo,
      age: parseInt(e.target.value),
    })
  }

  const handleConfirmClick = () => {
    if (userInfo.name === '') {
      setErrorTitle('Ton prénom est requis')
      setErrorText('Il faut renseigner ton prénom afin de pouvoir continuer')
      setOpen(true)
    } else if (userInfo.name.length >= 25) {
      setErrorTitle("C'est trop long")
      setErrorText('Ton prénom doit faire moins de 25 caractères')
      setOpen(true)
    } else {
      setScreenToggler(false)
    }
  }

  const handleAgeVerifier = () => {
    if (userInfo.age === -1 || userInfo.age === '' || !userInfo.age) {
      setErrorTitle('Ton âge est requis')
      setErrorText('Il faut renseigner ton âge afin de pouvoir continuer')
      setOpen(true)
    } else if (userInfo.age < 16) {
      setUserInfo({
        ...userInfo,
        age: parseInt(-1),
      })
      setErrorTitle('Tu es un peu trop jeune')
      setErrorText(
        'Pour ta recherche d’emploi et de formation professionnelle, n’hésite pas à te rapprocher des organismes d’orientation de l’Éducation nationale'
      )
      setOpen(true)
    } else if (userInfo.age > 65) {
      setUserInfo({
        ...userInfo,
        age: parseInt(-1),
      })
      setErrorTitle('Tu es un peu trop âgé')
      setErrorText(
        'Tu ne pourras pas profiter pleinement des conseils et des bonnes pratiques de ce jeu, profite plutôt de ta retraite'
      )
      setOpen(true)
    } else {
      handleScreenNext()
    }
  }

  return (
    <>
      <CustomAgeDialog
        open={open}
        setOpen={setOpen}
        errorTitle={errorTitle}
        errorText={errorText}
      />

      {screenToggler ? (
        <div className={classes.root}>
          <div>
            <CustomPaper>
              <ContentWrapper>
                <Box mt={2}>
                  <CustomTitle position="center">
                    Quel est ton prénom ?
                  </CustomTitle>
                </Box>
                <Grid container justify="center">
                  <TextField
                    className={classes.textfield}
                    id="username"
                    label="Prénom"
                    variant="outlined"
                    margin="dense"
                    value={userInfo.name}
                    onChange={(e) => handleUserNameChange(e)}
                  />
                </Grid>
                <CustomButton handleNext={handleConfirmClick}>
                  valider
                </CustomButton>
              </ContentWrapper>
            </CustomPaper>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <div>
            <CustomPaper>
              <ContentWrapper>
                <Box mt={2}>
                  <CustomTitle position="center">
                    Quel est ton âge ?
                  </CustomTitle>
                </Box>
                <Grid container justify="center">
                  <TextField
                    className={classes.textfield}
                    id="useage"
                    label="Âge"
                    variant="outlined"
                    margin="dense"
                    value={
                      !userInfo.age || userInfo.age <= -1 ? '' : userInfo.age
                    }
                    onChange={(e) => handleUserAgeChange(e)}
                  />
                </Grid>
                <CustomButton handleNext={handleAgeVerifier}>
                  valider
                </CustomButton>
              </ContentWrapper>
            </CustomPaper>
          </div>
        </div>
      )}
    </>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  textfield: {
    marginBottom: theme.spacing(4),
    width: '300px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      color: theme.custom.textColor,
    },
    '& .MuiFormLabel-root': {
      color: theme.custom.titleColor,
    },
    '& label.Mui-focused': {
      color: theme.custom.titleColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.custom.titleColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.custom.titleColor,
      },
      '&:hover fieldset': {
        borderColor: theme.custom.titleColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.custom.titleColor,
      },
    },
  },
}))
