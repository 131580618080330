import { Avatar } from '@material-ui/core'

const CustomAvatar = (props) => {
  const { seed } = props

  return (
    <div>
      <Avatar
        style={{
          width: '2rem',
          height: '2rem',
        }}
        src={`https://avatars.dicebear.com/api/human/${seed}.svg`}
      />
    </div>
  )
}

export default CustomAvatar
