import React from 'react'
import Grid from '@material-ui/core/Grid'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'
import Screen03 from './screens/Screen03'
import Screen04 from './screens/Screen04'

const Step01 = (props) => {
  const { screen, setScreen, userInfo, setUserInfo, handleStepNext } = props

  const handleScreenNext = () => {
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  return (
    <Step01Stepper screen={screen}>
      <Step01Screen>
        <Screen01 handleScreenNext={handleScreenNext} />
      </Step01Screen>
      <Step01Screen>
        <Screen02
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          handleScreenNext={handleScreenNext}
        />
      </Step01Screen>
      <Step01Screen>
        <Screen03
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          handleScreenNext={handleScreenNext}
        />
      </Step01Screen>
      <Step01Screen>
        <Screen04 userInfo={userInfo} handleScreenReset={handleScreenReset} />
      </Step01Screen>
    </Step01Stepper>
  )
}

const Step01Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <Grid item>{currentChild}</Grid>
}

const Step01Screen = ({ children }) => {
  return <>{children}</>
}

export default Step01
