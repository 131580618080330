import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'

import CustomButton from '../../../../shared/CustomButton'
import phoneSkeleton from '../../../../images/smartphone_tall.svg'
import GeneralMessage from '../../../../shared/GeneralMessage'
import PersonnalMessage from '../../../../shared/PersonnalMessage'

import { topFunction } from '../../../../utils'

import { phoneBg } from '../../../../images'

const Screen04 = (props) => {
  const { userInfo, handleScreenReset } = props

  const classes = useStyles()

  const [anim1, setAnim1] = useState('')
  const [anim2, setAnim2] = useState('')
  const [anim3, setAnim3] = useState('')
  const [anim4, setAnim4] = useState('')
  const [anim5, setAnim5] = useState('')

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
    setTimeout(() => setAnim1('visible'), 500)
    setTimeout(() => setAnim2('visible'), 1500)
    setTimeout(() => setAnim3('visible'), 2500)
    setTimeout(() => setAnim4('visible'), 3500)
    setTimeout(() => setAnim5('visible'), 4500)
  }, [])

  return (
    <div className={classes.root}>
      <div style={{ position: 'relative' }}>
        <div className={classes.phoneBG} />
        <div className={classes.phoneSkeleton}>
          <div>
            <div className={classes.animWrapper}>
              <div className={`${classes.message01} ${anim1}`}>
                <GeneralMessage
                  seed={userInfo.friendAvatar}
                  friendName="Ami"
                  content={`Ah c'est très bien d'avoir un projet, n’oublie pas de faire ton C.V, une Lettre de Motivation, et aussi de te renseigner auprès des organismes compétents`}
                />
              </div>
            </div>

            <div className={classes.animWrapper}>
              <div className={`${classes.message02} ${anim2}`}>
                <PersonnalMessage
                  seed={userInfo.myAvatar}
                  friendName={userInfo.name}
                  content="Oui tu as raison je vais m'en occuper tout de suite, merci beaucoup de ton aide"
                />
              </div>
            </div>

            <div className={classes.animWrapper}>
              <div className={`${classes.message01} ${anim3}`}>
                <GeneralMessage
                  seed={userInfo.friendAvatar}
                  friendName="Ami"
                  content={`Je t'en prie c'est normal, bon courage dans tes recherches, à bientôt`}
                />
              </div>
            </div>

            <div className={classes.animWrapper}>
              <div className={`${classes.message02} ${anim4}`}>
                <PersonnalMessage
                  seed={userInfo.myAvatar}
                  friendName={userInfo.name}
                  content="À bientôt !"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.animBtnWrapper}>
        <div className={`${classes.message05} ${anim5}`}>
          <CustomButton handleNext={handleScreenReset}>
            commencer à faire son cv
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default Screen04

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    minHeight: '75vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneBG: {
    position: 'absolute',
    content: "''",
    top: '70px',
    left: '010px',
    width: '320px',
    height: '680px',
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6)100%),  url(${phoneBg})`,
    backgroundColor: '#ffffff',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  phoneSkeleton: {
    width: '350px',
    height: '850px',
    backgroundImage: `url(${phoneSkeleton})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    position: 'relative',
    '& > div': {
      padding: '105px 40px',
    },
  },
  animWrapper: {
    overflow: 'hidden',
  },
  animBtnWrapper: {
    marginTop: '2rem',
    overflow: 'hidden',
  },
  message01: {
    transform: 'translateX(-300px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
  message02: {
    transform: 'translateX(300px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
  message05: {
    transform: 'translateY(50px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
}))
