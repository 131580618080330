import { makeStyles } from '@material-ui/core'

const ContentWrapper = (props) => {
  const { children } = props

  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default ContentWrapper

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}))
