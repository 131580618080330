import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'

import CustomButton from '../../../../shared/CustomButton'
import phoneSkeleton from '../../../../images/smartphone_tall.svg'
import GeneralMessage from '../../../../shared/GeneralMessage'
import PersonnalMessage from '../../../../shared/PersonnalMessage'

import { phoneBg } from '../../../../images'

const Screen02 = (props) => {
  const { handleScreenReset, interviewDate, userInfo } = props

  const classes = useStyles()

  const [anim1, setAnim1] = useState('')
  const [anim2, setAnim2] = useState('')
  const [anim3, setAnim3] = useState('')
  const [anim4, setAnim4] = useState('')
  const [anim5, setAnim5] = useState('')
  const [anim6, setAnim6] = useState('')

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
    setTimeout(() => setAnim1('visible'), 500)
    setTimeout(() => setAnim2('visible'), 1500)
    setTimeout(() => setAnim3('visible'), 2500)
    setTimeout(() => setAnim4('visible'), 3500)
    setTimeout(() => setAnim5('visible'), 4500)
    setTimeout(() => setAnim6('visible'), 5500)
  }, [])

  const getTextForMessageContent = () => {
    if (userInfo.wish === 'Emploi') {
      return 'mon emploi'
    } else if (userInfo.wish === 'Formation') {
      return 'ma formation'
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div style={{ position: 'relative' }}>
          <div className={classes.phoneBG} />
          <div className={classes.phoneSkeleton}>
            <div>
              <div className={classes.animWrapper}>
                <div className={`${classes.message01} ${anim1}`}>
                  <GeneralMessage
                    seed={userInfo.friendAvatar}
                    friendName="Ami"
                    content={`Coucou c'est encore moi, alors comment se passent tes recherches ?`}
                  />
                </div>
              </div>

              <div className={classes.animWrapper}>
                <div className={`${classes.message02} ${anim2}`}>
                  <PersonnalMessage
                    seed={userInfo.myAvatar}
                    friendName={userInfo.name}
                    content={`J'ai un entretien ${interviewDate} à 9h30 pour ${getTextForMessageContent()} !`}
                  />
                </div>
              </div>

              <div className={classes.animWrapper}>
                <div className={`${classes.message01} ${anim3}`}>
                  <GeneralMessage
                    seed={userInfo.friendAvatar}
                    friendName="Ami"
                    content={`C'est Super ça, tu devrais t'entraîner à cet entretien afin d'avoir toutes les cartes en main le jour J !`}
                  />
                </div>
              </div>

              <div className={classes.animWrapper}>
                <div className={`${classes.message02} ${anim4}`}>
                  <PersonnalMessage
                    seed={userInfo.myAvatar}
                    friendName={userInfo.name}
                    content="Tu as raison je vais me préparer, merci encore, à bientôt !"
                  />
                </div>
              </div>

              <div className={classes.animWrapper}>
                <div className={`${classes.message01} ${anim5}`}>
                  <GeneralMessage
                    seed={userInfo.friendAvatar}
                    friendName="Ami"
                    content={`Bonne chance pour ton entretien :)`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.animBtnWrapper}>
          <div className={`${classes.btn01} ${anim6}`}>
            <CustomButton handleNext={handleScreenReset}>
              préparer son entretien
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    minHeight: '75vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneBG: {
    position: 'absolute',
    content: "''",
    top: '70px',
    left: '010px',
    width: '320px',
    height: '680px',
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6)100%),  url(${phoneBg})`,
    backgroundColor: '#ffffff',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
  phoneSkeleton: {
    width: '350px',
    height: '850px',
    backgroundImage: `url(${phoneSkeleton})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    position: 'relative',
    '& > div': {
      padding: '105px 40px',
    },
  },
  animWrapper: {
    overflow: 'hidden',
  },
  animBtnWrapper: {
    marginTop: '2rem',
    overflow: 'hidden',
  },
  message01: {
    transform: 'translateX(-300px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
  message02: {
    transform: 'translateX(300px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
  btn01: {
    transform: 'translateY(50px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
}))
