import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'
import { txt } from '../content'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import FsLightbox from 'fslightbox-react'
import CardMedia from '@material-ui/core/CardMedia'

import { cvGood } from '../../../../images'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'

const Screen02 = (props) => {
  const { isCorrect, handleScreenReset, toggler, setToggler } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <>
      <CustomPaper>
        <ContentWrapper>
          {isCorrect === 1 && (
            <>
              <CustomTitle position="center">
                Bravo, tu as choisi le meilleur modèle.
              </CustomTitle>
              <CustomText position="center">
                C’était le meilleur CV à choisir, il est sans fautes, possède
                une mise en forme, de la couleur et toutes les informations
                attendues dans un CV.
              </CustomText>
            </>
          )}
          {isCorrect === 2 && (
            <>
              <CustomTitle position="center">
                Pas mal, mais il y avait un meilleur choix.
              </CustomTitle>
              <CustomText position="center">
                Ce CV est correct mais il pourrait être meilleur en rajoutant de
                la mise en forme et de la couleur pour que le CV ne soit pas
                trop austère ni uniquement en noir et blanc. Cela te permettra
                de te démarquer des autres.
              </CustomText>
              <CustomText position="center">{txt}</CustomText>
            </>
          )}
          {isCorrect === 3 && (
            <>
              <CustomTitle position="center">
                Non, celui-ci ne peut pas convenir
              </CustomTitle>
              <CustomText position="center">
                C’était le pire CV à choisir (avoue, tu l’as fait exprès ?), il
                n’a aucune mise en forme, a des fautes, s’exprime d’une manière
                trop familière et tout un tas d’autres problèmes, c’est simple:
                “Il y a rien qui va !”
              </CustomText>
              <CustomText position="center">{txt}</CustomText>
            </>
          )}

          <Grid
            container
            justify="center"
            style={{ marginBottom: '2rem' }}
          ></Grid>

          <Grid container justify="center" style={{ marginBottom: '2rem' }}>
            <Grid item xs={12}>
              <Card className={classes.itemContainer}>
                <CardMedia
                  className={classes.media}
                  image={cvGood}
                  title="curriculum-vitae"
                  onClick={() => setToggler(!toggler)}
                />
              </Card>
            </Grid>
          </Grid>
          <FsLightbox toggler={toggler} sources={[cvGood]} />
          <CustomButton handleNext={handleScreenReset}>
            Passer à la lettre de motivation
          </CustomButton>
        </ContentWrapper>
      </CustomPaper>
    </>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: ' 100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(3),
  },
  media: {
    cursor: 'zoom-in',
    height: '100%',
  },
  itemContainer: {
    width: '100%',
    height: '1000px',
  },
  isSelected: {
    boxShadow:
      '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0.2), -5px 5px 15px rgba(0, 0, 0, 0.2), 5px -5px 15px rgba(0, 0, 0, 0.2)',
  },
  confirm: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
}))
