import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CustomAvatar from './CustomAvatar'

const GeneralMessage = (props) => {
  const { friendName, content, seed } = props

  const classes = useStyles()
  return (
    <>
      <div className={classes.userDetailWrapper}>
        <CustomAvatar seed={seed} />
        <Typography className={classes.userName}>{friendName}</Typography>
      </div>

      <div className={classes.messageWrapper}>
        <Typography className={classes.messageContent}>{content}</Typography>
      </div>
    </>
  )
}

export default GeneralMessage

const useStyles = makeStyles((theme) => ({
  root: {},
  userDetailWrapper: {
    marginTop: '0.75rem',
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '1.1&5',
    marginLeft: '0.25rem',
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  messageContent: {
    backgroundColor: '#fff',
    marginTop: '0.5rem',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    fontSize: '14px',
    fontWeight: 700,
    color: '#191919',
  },
}))
