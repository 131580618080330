import { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import CustomButton from '../../../../shared/CustomButton'
import CustomTitle from '../../../../shared/CustomTitle'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomAgeDialog from '../../../../shared/CustomAgeDialog'

const Screen03 = (props) => {
  const { handleScreenNext, userInfo, setUserInfo } = props

  const classes = useStyles()

  const [jobToogler, setJobToogler] = useState(false)
  const [schoolToogler, setSchoolToogler] = useState(false)
  const [open, setOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [errorTitle, setErrorTitle] = useState('')

  const handleChoiceVerifier = () => {
    if (userInfo.wish === '') {
      setErrorTitle('Ton orientation est requise')
      setErrorText('Il faut renseigner ton souhait afin de pouvoir continuer')
      setOpen(true)
    } else {
      handleScreenNext()
    }
  }

  const handleJobBtnClick = () => {
    setSchoolToogler(false)
    setJobToogler(true)
    setUserInfo({
      ...userInfo,
      wish: 'Emploi',
    })
  }

  const handleSchoolBtnClick = () => {
    setJobToogler(false)
    setSchoolToogler(true)
    setUserInfo({
      ...userInfo,
      wish: 'Formation',
    })
  }

  return (
    <>
      <CustomAgeDialog
        open={open}
        setOpen={setOpen}
        errorTitle={errorTitle}
        errorText={errorText}
      />

      <div className={classes.root}>
        <div>
          <CustomPaper>
            <ContentWrapper>
              <Box mt={2}>
                <CustomTitle position="center">
                  Au fait, j'ai oublié de te demander, où en es-tu
                  professionnellement parlant ? Qu'est-ce que tu voudrais faire
                  ?
                </CustomTitle>
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    onClick={handleJobBtnClick}
                    className={`${classes.answer} ${jobToogler && 'selected'}`}
                  >
                    <p>Trouver un Emploi</p>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    onClick={handleSchoolBtnClick}
                    className={`${classes.answer} ${
                      schoolToogler && 'selected'
                    }`}
                  >
                    <p>Trouver une Formation</p>
                  </Paper>
                </Grid>
              </Grid>
              <CustomButton handleNext={handleChoiceVerifier}>
                valider le choix
              </CustomButton>
            </ContentWrapper>
          </CustomPaper>
        </div>
      </div>
    </>
  )
}

export default Screen03

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  answer: {
    marginBottom: theme.spacing(4),
    backgroundColor: 'transparent',
    height: '48px',
    // backgroundColor: theme.custom.appBgColor,
    textTransform: 'lowercase',
    fontWeight: 700,
    color: theme.custom.titleColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: "'Quicksand', sans-serif",
    border: '2px solid transparent',
    cursor: 'pointer',
    '& p': {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
    '&:hover': {
      border: '2px solid rgba(135,7,248,1)',
      color: theme.palette.common.black,
    },
    '&.selected': {
      background: theme.custom.answerColorHover,
      color: theme.palette.common.white,
      border: '2px solid transparent',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}))
