import { Box, Grid, makeStyles } from '@material-ui/core'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import Sponsor from '../../../home/Sponsor'

const Screen03 = (props) => {
  const { userInfo } = props

  const classes = useStyles()

  const getUserWish = () => {
    if (userInfo.wish === 'Formation') {
      return 'en formation.'
    } else if (userInfo.wish === 'Emploi') {
      return 'embauché(e).'
    }
  }

  return (
    <div className={classes.appWrapper}>
      <CustomPaper className={classes.root}>
        <ContentWrapper>
          <CustomTitle position="center">Bravo tu as réussi !</CustomTitle>
          <CustomTitle position="center">
            Tu es maintenant {getUserWish()}
          </CustomTitle>
          <CustomText position="center">
            Si tu le souhaites tu peux récupérer l’ensemble des conseils
            dispensés au cours du jeu en cliquant sur le bouton ci-dessous pour
            télécharger une fiche récapitulative au format .pdf.
          </CustomText>
          <Grid>
            <Grid item className={classes.downloaderWrapper}>
              {userInfo.wish === 'Emploi' && (
                <a
                  download
                  href="./files/pdf/milo-guide-embauche.pdf"
                  className={classes.downloader}
                >
                  Télécharger ( ~1mo )
                </a>
              )}
              {userInfo.wish === 'Formation' && (
                <a
                  download
                  href="./files/pdf/milo-guide-embauche.pdf"
                  className={classes.downloader}
                >
                  Télécharger
                </a>
              )}
            </Grid>
          </Grid>
        </ContentWrapper>
      </CustomPaper>

      <Box mt={4}>
        <CustomPaper className={classes.root}>
          <ContentWrapper>
            <Sponsor />
          </ContentWrapper>
        </CustomPaper>
      </Box>
    </div>
  )
}

export default Screen03

const useStyles = makeStyles((theme) => ({
  appWrapper: {
    padding: '3rem 0',
    minHeight: '75vh',
  },
  root: {
    backgroundColor: '#1F2937',
  },
  downloaderWrapper: {
    padding: '1.5rem 0 1rem',
    textAlign: 'center',
  },
  downloader: {
    fontFamily: "'Quicksand', sans-serif",
    background:
      'linear-gradient(90deg, rgba(135,7,248,1) 0%, rgba(235,13,248,1) 100%)',
    borderRadius: 3,
    textTransform: 'capitalize',
    fontWeight: 700,
    color: '#FFFFFF',
    height: 48,
    padding: '20px 30px',
    marginTop: '0rem',
    textDecoration: 'none',
    '&:hover': {
      textUnderlineOffset: '0.25rem',
      textDecoration: 'underline',
      textDecorationThickness: 2,
    },
  },
}))
