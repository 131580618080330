export const jobQuizzData = {
  title: 'Quizz pour un emploi',
  questions: [
    {
      label: 'Quel type de langage adoptes-tu durant un entretien ?',
      answers: [
        {
          content: 'Soutenu et courant',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, tu adoptes le langage adéquat, un langage trop soutenu peut être inadapté selon le poste',
        },
        {
          content: 'Courant',
          score: 5,
          selected: false,
          contentAnswer:
            'Effectivement, suivant ton langage courant, ce langage peut-être accepté',
        },
        {
          content: 'Familier',
          score: 2,
          selected: false,
          contentAnswer:
            'Le langage familier n’est pas un langage à adopter devant un employeur',
        },
        {
          content: 'Je lui parle latin',
          score: 0,
          selected: false,
          contentAnswer:
            'Non de Zeus Bravo !! tu as gagné ! Non, c’est une blague...',
        },
      ],
    },
    {
      label: 'Comment t’habilles-tu avant d’aller en entretien ?',
      answers: [
        {
          content: 'Costume adapté au métier',
          score: 10,
          selected: false,
          contentAnswer: 'Bravo, tu as opté pour la meilleure tenue',
        },
        {
          content: 'Tenue de Ville',
          score: 5,
          selected: false,
          contentAnswer:
            'Cette tenue reste présentable, cependant ce n’est pas la meilleure devant un employeur selon le métier',
        },
        {
          content: 'Survêtement',
          score: 2,
          selected: false,
          contentAnswer:
            'Cette tenue n’est évidemment pas adaptée devant un employeur',
        },
        {
          content: 'Claquette-Chaussette',
          score: 0,
          selected: false,
          contentAnswer: 'Faux, à moins que ce soit un maître nageur Norvégien',
        },
      ],
    },
    {
      label:
        'Il est actuellement 14h, tu as rendez-vous  à 16h pour un entretien d’embauche que fais-tu ?',
      answers: [
        {
          content: 'Je me prépare pour l’entretien et je pars en avance',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, effectivement, c’est le bon comportement à avoir',
        },
        {
          content: 'Je me repose un coup et pars de façon à arriver à l’heure',
          score: 5,
          selected: false,
          contentAnswer:
            'Il est bien d’arriver à l’heure, cependant, arriver en avance est toujours mieux',
        },
        {
          content: 'Je pars attendre mon tram à 15h50',
          score: 2,
          selected: false,
          contentAnswer:
            'Très mauvaise idée, tu vas arriver en retard à ton entretien',
        },
        {
          content: 'J’invite un ami à la maison pour jouer à la Z-Box',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, encore si tu étais allé(e) faire du vélo ça aurait pu passer',
        },
      ],
    },
    {
      label: 'À quelle heure arrives-tu à ton entretien ?',
      answers: [
        {
          content: '15 min en avance',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, tu as raison, il est toujours préférable d’arriver en avance à son entretien',
        },
        {
          content: "Pile à l'heure",
          score: 5,
          selected: false,
          contentAnswer:
            'Il est bien d’arriver à l’heure, cependant, arriver en avance est toujours mieux',
        },
        {
          content: '15 min en retard',
          score: 2,
          selected: false,
          contentAnswer:
            'Arriver en retard donne déjà à l’employeur une très mauvaise image de toi',
        },
        {
          content: 'Je ne viens pas',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, si tu n’y va pas comment comptes-tu faire l’entretien ?',
        },
      ],
    },
    {
      label: 'Qu’est-ce que tu apportes à l’entretien ?',
      answers: [
        {
          content:
            'CV + Lettre de motivation + de quoi écrire et des infos sur l’entreprise',
          score: 10,
          selected: false,
          contentAnswer: 'Bravo, tu apportes tout ce dont tu as besoin',
        },
        {
          content: 'CV + Lettre de motivation',
          score: 5,
          selected: false,
          contentAnswer:
            'C’est déjà bien, malgré tout, apporter de quoi écrire est toujours mieux',
        },
        {
          content: 'Je viens sans documents',
          score: 2,
          selected: false,
          contentAnswer:
            'Venir sans document ne fait pas sérieux et donne une mauvaise image de toi',
        },
        {
          content:
            'Je viens avec mon livre de cuisine ça fera plaisir au patron',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, sauf si tu as caché les documents nécessaires dans le livre de cuisine ?? Hmm...',
        },
      ],
    },
    {
      label: 'Le patron te demande de citer 2 qualités et 2 défauts ?',
      answers: [
        {
          content:
            "Je prévois cette question,  je sais quoi répondre, je fais semblant d'hésiter",
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, en effet cette question est souvent posée, il est donc bon de l’anticiper',
        },
        {
          content: 'Je réfléchis le moment venu',
          score: 5,
          selected: false,
          contentAnswer:
            'Cette manière de faire est possible, cependant il est toujours mieux d’anticiper',
        },
        {
          content: 'Je change de sujet',
          score: 2,
          selected: false,
          contentAnswer:
            'Ce n’est pas une bonne idée, au contraire ça ne fera que te desservir',
        },
        {
          content: 'Je n’ai pas de défaut',
          score: 0,
          selected: false,
          contentAnswer: 'Faux, Narcisse sors de ce corps !!',
        },
      ],
    },
    {
      label: 'Quel est ton niveau d’anglais ?',
      answers: [
        {
          content:
            'Suite à une section anglais au lycée et un voyage en Angleterre, j’ai pu perfectionner mon anglais et je le parle couramment',
          score: 10,
          selected: false,
          contentAnswer:
            'Parler couramment anglais est un excellent point fort',
        },
        {
          content:
            'J’ai fait LV1 anglais au lycée, j’ai par conséquent un niveau normal',
          score: 5,
          selected: false,
          contentAnswer:
            'C’est déjà bien, mais il est vrai qu’un niveau courant sera plus apprécié',
        },
        {
          content: 'Je ne parle pas du tout anglais',
          score: 2,
          selected: false,
          contentAnswer:
            'Ne pas avoir de connaissance en anglais n’est pas très valorisant devant un employeur',
        },
        {
          content: 'Me gusta la playa',
          score: 0,
          selected: false,
          contentAnswer: 'Faux, este nao é o idioma certo!',
        },
      ],
    },
    {
      label: 'Quelles sont tes disponibilités ?',
      answers: [
        {
          content: 'Tous les jours, hormis le dimanche',
          score: 10,
          selected: false,
          contentAnswer: 'Cette disponibilité satisfera tout employeur',
        },
        {
          content: 'Je suis disponible lundi, mardi, jeudi et vendredi',
          score: 5,
          selected: false,
          contentAnswer:
            'Bien qu’elle soit moins large, cette disponibilité reste satisfaisante',
        },
        {
          content: 'Je ne sais pas, je suis très peu disponible en ce moment',
          score: 2,
          selected: false,
          contentAnswer:
            'En tant que chercheur d’emploi, cette réponse ne sera certainement pas appréciée par l’employeur',
        },
        {
          content: 'Uniquement l’après-midi, le matin je dors',
          score: 0,
          selected: false,
          contentAnswer: "Faux, tu t'es couché(e) à quelle heure encore...",
        },
      ],
    },
    {
      label: 'Quel type de difficulté as-tu du mal à gérer ?',
      answers: [
        {
          content:
            'Je n’ai pas vraiment de difficultés je m’adapte à toutes les situations',
          score: 10,
          selected: false,
          contentAnswer:
            'Cette capacité d’adaptation rassurera certainement l’employeur',
        },
        {
          content:
            'Je peux avoir quelques difficultés face aux changements, mais je parviens à les gérer',
          score: 5,
          selected: false,
          contentAnswer:
            'Ces difficultés pourraient inquiéter, cependant si tu arrives à les gérer, c’est toujours mieux',
        },
        {
          content: 'J’ai beaucoup de mal à gérer mon stress',
          score: 2,
          selected: false,
          contentAnswer:
            'Cette réponse pourrait fortement inquiéter l’employeur',
        },
        {
          content: 'Je n’aime pas les gens et encore moins travailler',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, la vie est dure certes, mais il ne faut pas se laisser abattre, courage !',
        },
      ],
    },
    {
      label: 'Quelle est la bonne posture à adopter face à l’employeur ?',
      answers: [
        {
          content:
            'Je me tiens le dos droit contre la chaise avec une attitude ouverte à la discussion',
          score: 10,
          selected: false,
          contentAnswer:
            'Tu as adopté la bonne posture, propice à un échange dans de bonnes conditions',
        },
        {
          content:
            "Je m'assois en me penchant vers mon interlocuteur les coudes sur la table",
          score: 5,
          selected: false,
          contentAnswer:
            'Cette position peut te donner un air intéressé mais attention à ne pas être trop proche !',
        },
        {
          content:
            "Je reste debout,  je m'appuie contre un mur et je croise les bras",
          score: 2,
          selected: false,
          contentAnswer: "Franchement, ça ne te donnera pas l'air plus cool !",
        },
        {
          content:
            'Je fais le poirier avec pour montrer mes capacités d’acrobate avant de m’asseoir par terre',
          score: 0,
          selected: false,
          contentAnswer:
            'À moins de postuler dans un cirque, c’est une très mauvaise idée !',
        },
      ],
    },
  ],
}

export const schoolQuizzData = {
  title: 'Quizz pour une formation',
  questions: [
    {
      label: 'Quel est le type de langage adoptes-tu durant un entretien  ?',
      answers: [
        {
          content: 'Soutenu et Courant',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, tu adoptes le langage adéquat, un langage trop soutenu peut être inadapté selon le poste',
        },
        {
          content: 'Courant',
          score: 5,
          selected: false,
          contentAnswer:
            'Effectivement, suivant ton langage courant, ce langage peut-être accepté',
        },
        {
          content: 'Familier',
          score: 2,
          selected: false,
          contentAnswer:
            'Le langage familier n’est pas un langage à adopter devant un recruteur',
        },
        {
          content: 'Je lui parle Latin',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, le latin est une langue morte, comme ton avenir si tu continues comme ça',
        },
      ],
    },
    {
      label: 'Où te vois-tu dans cinq ans ?',
      answers: [
        {
          content: 'Dans une très bonne entreprise faire le métier que j’aime',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, en effet cette vision de l’avenir pourrait rassurer et satisfaire le recruteur',
        },
        {
          content:
            'J’ai du mal à imaginer l’avenir mais je me vois quand même salarié(e)',
          score: 5,
          selected: false,
          contentAnswer:
            'Te voir en tant que salarié(e) est déjà bien, cependant avoir du mal à percevoir l’avenir pourrait inquiéter le recruteur',
        },
        {
          content: 'Je ne sais pas',
          score: 2,
          selected: false,
          contentAnswer:
            'Ne pas savoir répondre à cette question peut inquiéter et rendre sceptique le recruteur pour l’avenir avec toi',
        },
        {
          content: 'Riche en vacances aux bahamas',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, alors pour y arriver commence par répondre correctement',
        },
      ],
    },
    {
      label:
        'Il est actuellement 14h, tu as rendez-vous à 16h pour un entretien d’entrée en formation que fais-tu ?',
      answers: [
        {
          content: 'Je me prépare pour l’entretien et je pars en avance',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, effectivement, c’est le bon comportement à avoir',
        },
        {
          content: 'Je me repose un coup et pars de façon à arriver à l’heure',
          score: 5,
          selected: false,
          contentAnswer:
            'Il est bien d’arriver à l’heure, cependant, arriver en avance est toujours mieux',
        },
        {
          content: 'Je pars attendre mon tram à 15h50',
          score: 2,
          selected: false,
          contentAnswer:
            'Très mauvaise idée, tu vas arriver en retard à ton entretien',
        },
        {
          content: 'J’invite un ami à la maison pour jouer à la Z-Box',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, le meilleur moyen pour écourter ton parcours professionnel !',
        },
      ],
    },
    {
      label: 'À quelle heure arrives- tu à ton entretien ?',
      answers: [
        {
          content: '15 min en avance',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, tu as raison, il est toujours préférable d’arriver en avance à son entretien',
        },
        {
          content: "Pile à l'heure",
          score: 5,
          selected: false,
          contentAnswer:
            'Il est bien d’arriver à l’heure, cependant, arriver en avance est toujours mieux',
        },
        {
          content: '15 min en retard',
          score: 2,
          selected: false,
          contentAnswer:
            'Arriver en retard donne déjà au recruteur une très mauvaise image de toi',
        },
        {
          content: 'Je ne viens pas',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, alors tu vas où dans ce cas ? Jouer à la Z-box ?',
        },
      ],
    },
    {
      label: 'Qu’apportes-tu  à l’entretien ?',
      answers: [
        {
          content:
            'CV + Lettre de motivation + de quoi écrire et des infos sur la formation',
          score: 10,
          selected: false,
          contentAnswer: 'Bravo, tu apportes tout ce dont tu as besoin',
        },
        {
          content: 'CV + Lettre de motivation',
          score: 5,
          selected: false,
          contentAnswer:
            'C’est déjà bien, malgré tout, apporter de quoi écrire est toujours mieux',
        },
        {
          content: 'Je  viens sans documents',
          score: 2,
          selected: false,
          contentAnswer:
            'Venir sans document ne fait pas sérieux et donne une mauvaise image de toi',
        },
        {
          content:
            'Je viens avec mon livre de cuisine, ça fera plaisir au recruteur',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, prends aussi une tarte aux pruneaux c’est super bon tant qu’à faire !',
        },
      ],
    },
    {
      label: 'Est-ce que ton  projet professionnel est défini ?',
      answers: [
        {
          content: 'Je réponds oui et je développe',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, avoir un projet déjà parfaitement défini rassurera le recruteur',
        },
        {
          content: 'Non, je suis en découverte',
          score: 5,
          selected: false,
          contentAnswer:
            'Le recruteur pourrait apprécier ta détermination à en découvrir plus, cependant le fait que tu n’aies pas de projet reste délicat',
        },
        {
          content: 'Je n’ai aucun projet d’avenir',
          score: 2,
          selected: false,
          contentAnswer:
            'Le recruteur risque d’être réticent sur le fait que tu n’aies pas de projet, et pourrait ne pas envisager la suite avec toi',
        },
        {
          content: 'Mon projet c’est que je n’ai pas de projet',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, mon plan B c’est que je n’ai pas de plan B ! Si tu as la ref répond correctement aux réponses et tu auras une surprise !',
        },
      ],
    },
    {
      label: "As-tu de l'expérience dans le domaine ?",
      answers: [
        {
          content: 'Oui, j’ai fait des stages dans ce domaine',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, cette réponse plaira certainement au recruteur qui aura par conséquent confiance toi',
        },
        {
          content: 'Non, mais j’aimerais bien le découvrir',
          score: 5,
          selected: false,
          contentAnswer:
            'Ta détermination à découvrir ce domaine plaira au recruteur mais ton manque d’expérience est un point faible',
        },
        {
          content: 'Non, ce n’est pas un domaine qui m’intéresse',
          score: 2,
          selected: false,
          contentAnswer:
            'Ton manque de détermination et d’expérience ne satisfera pas du tout le recruteur que tu auras en face de toi',
        },
        {
          content: "J’ai beaucoup d'expériences dans les barbecues entre amis",
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, moi aussi mais je ne suis pas sur que ça t’aide à répondre à cette question...',
        },
      ],
    },
    {
      label: 'Quel est ton niveau d’anglais ?',
      answers: [
        {
          content:
            'Je mets beaucoup d’anglais dans mon quotidien (Série, Livre, Jeux) et ainsi  j’ai pu perfectionner mon niveau et je le parle presque couramment',
          score: 10,
          selected: false,
          contentAnswer:
            'Le niveau courant est souvent apprécié par les recruteurs',
        },
        {
          content:
            'J’ai fait LV1 anglais au collège, j’ai, par conséquent, un niveau normal',
          score: 5,
          selected: false,
          contentAnswer:
            'C’est déjà bien, mais il est vrai qu’un niveau bilingue sera plus apprécié ',
        },
        {
          content: 'Je ne parle pas du tout anglais',
          score: 2,
          selected: false,
          contentAnswer:
            'Ne pas avoir de connaissance en anglais n’est pas très valorisant devant un recruteur',
        },
        {
          content: 'Me gusta la playa',
          score: 0,
          selected: false,
          contentAnswer: 'Faux, Qué es la fecha de hoy ?',
        },
      ],
    },
    {
      label: 'Quelles sont tes disponibilités ?',
      answers: [
        {
          content: 'Tous les jours, hormis le dimanche',
          score: 10,
          selected: false,
          contentAnswer: 'Cette disponibilité satisfera tout recruteur',
        },
        {
          content: 'Je suis disponible le lundi, mardi, jeudi et vendredi',
          score: 5,
          selected: false,
          contentAnswer:
            'Bien qu’elle soit moins large, cette disponibilité reste satisfaisante',
        },
        {
          content: 'Je ne sais pas, je suis très peu disponible en ce moment',
          score: 2,
          selected: false,
          contentAnswer:
            'En tant que chercheur de formation, cette réponse ne sera certainement pas appréciée par le recruteur',
        },
        {
          content: 'Uniquement l’après midi le matin je dors',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, dors un peu moins et tu auras plus de temps pour moins dormir ! Euh....',
        },
      ],
    },
    {
      label: 'Connais-tu notre formation?',
      answers: [
        {
          content:
            'Oui, je me suis beaucoup renseigné(e), je suis allé(e) sur plusieurs sites internet et me suis informé(e) auprès de nombreux professionnels pour mieux vous connaître',
          score: 10,
          selected: false,
          contentAnswer:
            'Bravo, L’intérêt que tu portes à cette formation sera particulièrement apprécié par le recruteur',
        },
        {
          content: 'Oui, j’ai été sur votre site internet me renseigner',
          score: 5,
          selected: false,
          contentAnswer:
            'Le fait d’être aller visiter le site internet de l’organisme saura déjà satisfaire le recruteur, même si il reste mieux d’encore plus s’informer',
        },
        {
          content:
            'Non, j’admets que je ne connais pas grand chose en ce qui concerne votre formation',
          score: 2,
          selected: false,
          contentAnswer:
            'Ton désintéressement à propos de la formation pour laquelle tu postules ne plaira certainement pas au recruteur',
        },
        {
          content: 'Non, mais vous êtes à côté du Macdo c’est cool',
          score: 0,
          selected: false,
          contentAnswer:
            'Faux, mais qui sait, peut-être que tu les croiseras là-bas ?  Ça serait l’occasion de discuter...',
        },
      ],
    },
  ],
}

export const choiceCvData = [
  {
    image: 'https://picsum.photos/300/600',
    isCorrect: false,
  },
  {
    image: 'https://picsum.photos/300/600',
    isCorrect: true,
  },
  {
    image: 'https://picsum.photos/300/600',
    isCorrect: false,
  },
]

export const coverLetter = [
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-start',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-end',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-end',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-start',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'center',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-start',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-start',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-start',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
  {
    placeholder: 'Choisir le contenu à afficher',
    position: 'flex-start',
    value: '',
    items: [
      {
        label: 'Lorem Ipsum 01',
        isCorrect: true,
      },
      {
        label: 'Lorem Ipsum 02',
        isCorrect: false,
      },
      {
        label: 'Lorem Ipsum 03',
        isCorrect: false,
      },
    ],
  },
]
