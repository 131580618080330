import { Box, makeStyles } from '@material-ui/core'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import { prepaQuizz } from '../../../../images'
import { useEffect } from 'react'
import { topFunction } from '../../../../utils'

const Screen01 = (props) => {
  const { handleScreenNext } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper className={classes.root}>
          <ContentWrapper>
            <Box mt={2}>
              <CustomTitle position="center">Quizz de préparation</CustomTitle>
            </Box>
            <div style={{ padding: '1rem 0' }}>
              <div className={classes.imgWrapper}>
                <img
                  src={prepaQuizz}
                  alt="Illustration du quizz"
                  className={classes.img}
                />
              </div>
            </div>
            <CustomText position="center">
              Réponds à ces 10 questions, tu auras ensuite fini ton entraînement
              et tu pourras passer au véritable entretien.
            </CustomText>
            <CustomText position="center">
              À noter que ton nombre de points d'embauche pour l’entretien
              dépendra de ton score à la fin de cet entraînement. Bonne chance !
            </CustomText>
            <CustomButton handleNext={handleScreenNext}>
              Passer à la préparation
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  imgWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
}))
