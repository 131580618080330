import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import {
  workHeaderCoverLetter,
  workBodyCoverLetter,
  workFooterCoverLetter,
  schoolHeaderCoverLetter,
  schoolBodyCoverLetter,
  schoolFooterCoverLetter,
} from './content'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'
import Screen03 from './screens/Screen03'
import Screen04 from './screens/Screen04'
import Screen05 from './screens/Screen05'
import Screen06 from './screens/Screen06'
import Screen07 from './screens/Screen07'
import Screen08 from './screens/Screen08'
import Container from '@material-ui/core/Container'
import CustomAgeDialog from '../../../shared/CustomAgeDialog'

const Step03 = (props) => {
  const { screen, setScreen, userInfo, handleStepNext } = props

  const [open, setOpen] = useState(false)
  const [toggler, setToggler] = useState(false)
  const [isCorrect, setIsCorrect] = useState(0)
  const [selectedIdx, setSelectedIdx] = useState(-1)
  const [errorDial, setErrorDial] = useState({
    errorTitle: 'Test',
    errorText: 'Test content',
  })

  const handleScreenNext = () => {
    setSelectedIdx(-1)
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenBack = () => {
    setScreen((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentScreen', screen - 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  const handleWorkHeaderChoice = () => {
    if (selectedIdx === -1) {
      setErrorDial({
        ...errorDial,
        errorTitle: 'Une proposition doit être sélectionnée',
        errorText:
          "Il faut renseigner ton choix d'entête de lettre afin de pouvoir continuer",
      })
      setOpen(true)
    } else if (workHeaderCoverLetter[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (workHeaderCoverLetter[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (workHeaderCoverLetter[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  const handleWorkBodyChoice = () => {
    if (selectedIdx === -1) {
      setErrorDial({
        ...errorDial,
        errorTitle: 'Une proposition doit être sélectionnée',
        errorText:
          'Il faut renseigner ton choix de corps de lettre afin de pouvoir continuer',
      })
      setOpen(true)
    } else if (workBodyCoverLetter[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (workBodyCoverLetter[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (workBodyCoverLetter[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  const handleWorkFooterChoice = () => {
    if (selectedIdx === -1) {
      setErrorDial({
        ...errorDial,
        errorTitle: 'Une proposition doit être sélectionnée',
        errorText:
          'Il faut renseigner ton choix de pied de lettre afin de pouvoir continuer',
      })
      setOpen(true)
    } else if (workFooterCoverLetter[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (workFooterCoverLetter[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (workFooterCoverLetter[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  const handleSchoolHeaderChoice = () => {
    if (selectedIdx === -1) {
      setErrorDial({
        ...errorDial,
        errorTitle: 'Une proposition doit être sélectionnée',
        errorText:
          "Il faut renseigner ton choix d'entête de lettre afin de pouvoir continuer",
      })
      setOpen(true)
    } else if (schoolHeaderCoverLetter[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (schoolHeaderCoverLetter[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (schoolHeaderCoverLetter[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  const handleSchoolBodyChoice = () => {
    if (selectedIdx === -1) {
      setErrorDial({
        ...errorDial,
        errorTitle: 'Une proposition doit être sélectionnée',
        errorText:
          'Il faut renseigner ton choix de corps de lettre afin de pouvoir continuer',
      })
      setOpen(true)
    } else if (schoolBodyCoverLetter[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (schoolBodyCoverLetter[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (schoolBodyCoverLetter[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  const handleSchoolFooterChoice = () => {
    if (selectedIdx === -1) {
      setErrorDial({
        ...errorDial,
        errorTitle: 'Une proposition doit être sélectionnée',
        errorText:
          'Il faut renseigner ton choix de pied de lettre afin de pouvoir continuer',
      })
      setOpen(true)
    } else if (schoolFooterCoverLetter[selectedIdx].isCorrect === 1) {
      setIsCorrect(1)
      handleScreenNext()
    } else if (schoolFooterCoverLetter[selectedIdx].isCorrect === 2) {
      setIsCorrect(2)
      handleScreenNext()
    } else if (schoolFooterCoverLetter[selectedIdx].isCorrect === 3) {
      setIsCorrect(3)
      handleScreenNext()
    }
  }

  return (
    <>
      <CustomAgeDialog
        open={open}
        setOpen={setOpen}
        errorTitle={errorDial.errorTitle}
        errorText={errorDial.errorText}
      />
      <Container>
        <Step03Stepper
          handleScreenNext={handleScreenNext}
          handleScreenBack={handleScreenBack}
          screen={screen}
        >
          <Step03Screen>
            <Screen01 handleScreenNext={handleScreenNext} />
          </Step03Screen>
          <Step03Screen>
            <Screen02
              userWish={userInfo.wish}
              isCorrect={isCorrect}
              selectedIdx={selectedIdx}
              setSelectedIdx={setSelectedIdx}
              handleWorkHeaderChoice={handleWorkHeaderChoice}
              handleSchoolHeaderChoice={handleSchoolHeaderChoice}
            />
          </Step03Screen>
          <Step03Screen>
            <Screen03
              userWish={userInfo.wish}
              toggler={toggler}
              isCorrect={isCorrect}
              setToggler={setToggler}
              handleScreenNext={handleScreenNext}
            />
          </Step03Screen>
          <Step03Screen>
            <Screen04
              userWish={userInfo.wish}
              isCorrect={isCorrect}
              selectedIdx={selectedIdx}
              setSelectedIdx={setSelectedIdx}
              handleWorkBodyChoice={handleWorkBodyChoice}
              handleSchoolBodyChoice={handleSchoolBodyChoice}
            />
          </Step03Screen>
          <Step03Screen>
            <Screen05
              userWish={userInfo.wish}
              toggler={toggler}
              isCorrect={isCorrect}
              setToggler={setToggler}
              handleScreenNext={handleScreenNext}
            />
          </Step03Screen>
          <Step03Screen>
            <Screen06
              userWish={userInfo.wish}
              isCorrect={isCorrect}
              selectedIdx={selectedIdx}
              setSelectedIdx={setSelectedIdx}
              handleWorkFooterChoice={handleWorkFooterChoice}
              handleSchoolFooterChoice={handleSchoolFooterChoice}
            />
          </Step03Screen>
          <Step03Screen>
            <Screen07
              userWish={userInfo.wish}
              toggler={toggler}
              isCorrect={isCorrect}
              setToggler={setToggler}
              handleScreenNext={handleScreenNext}
            />
          </Step03Screen>
          <Step03Screen>
            <Screen08 handleScreenReset={handleScreenReset} />
          </Step03Screen>
        </Step03Stepper>
      </Container>
    </>
  )
}

const Step03Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <Grid item>{currentChild}</Grid>
}

const Step03Screen = ({ children }) => {
  return <>{children}</>
}

export default Step03
