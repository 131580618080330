import React from 'react'
import Grid from '@material-ui/core/Grid'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'
import Screen03 from './screens/Screen03'

const Step03 = (props) => {
  const { screen, setScreen, userInfo, handleStepNext } = props

  const handleScreenNext = () => {
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenBack = () => {
    setScreen((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentScreen', screen - 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  return (
    <Step04Stepper
      handleScreenNext={handleScreenNext}
      handleScreenBack={handleScreenBack}
      screen={screen}
    >
      <Step04Screen>
        <Grid item>
          <Screen01 handleScreenNext={handleScreenNext} userInfo={userInfo} />
        </Grid>
      </Step04Screen>
      <Step04Screen>
        <Grid item>
          <Screen02 handleScreenNext={handleScreenNext} userInfo={userInfo} />
        </Grid>
      </Step04Screen>
      <Step04Screen>
        <Grid item>
          <Screen03 handleScreenReset={handleScreenReset} userInfo={userInfo} />
        </Grid>
      </Step04Screen>
    </Step04Stepper>
  )
}

const Step04Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <>{currentChild}</>
}

const Step04Screen = ({ children }) => {
  return <>{children}</>
}

export default Step03
