export const choiceCvData = [
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'good',
    isCorrect: 1,
  },
  {
    image: 'bad',
    isCorrect: 3,
  },
]

export const txt = 'Voici le CV idéal :'
