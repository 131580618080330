import { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'

import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomButton from '../../../../shared/CustomButton'
import ContentWrapper from '../../../../shared/ContentWrapper'

import illustration from '../../../../images/jeune-cherche-emploi.jpg'

const Screen01 = (props) => {
  const { handleScreenNext } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            <CustomTitle position="center">
              Atelier Lettre de Motivation
            </CustomTitle>

            <Box mb={2}>
              <CustomText position="center">
                Pour composer la lettre de motivation idéale, choisis parmi les propositions le meilleur élément.
              </CustomText>
            </Box>
            <div style={{ width: '100%' }}>
              <img
                style={{ width: '100%', height: 'auto' }}
                src={illustration}
                alt="Jeune cherchant un emploi"
              />
            </div>
            <CustomButton handleNext={handleScreenNext}>Valider</CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
}))
