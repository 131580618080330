import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'

const CustomButton = (props) => {
  const { children, handleNext, type, full } = props

  return (
    <div style={{ textAlign: 'center' }}>
      <WithStyleButton
        color="secondary"
        variant="contained"
        type={type === 'submit' ? 'submit' : null}
        onClick={handleNext}
        fullWidth={full}
      >
        <p>{children}</p>
      </WithStyleButton>
    </div>
  )
}

export default CustomButton

const WithStyleButton = withStyles({
  root: {
    background:
      'linear-gradient(90deg, rgba(135,7,248,1) 0%, rgba(235,13,248,1) 100%)',
    borderRadius: 3,
    textTransform: 'lowercase',
    fontWeight: 700,
    color: '#FFFFFF',
    height: 48,
    padding: '0 25px',
    marginTop: '1rem',
    '& p': {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  },
  label: {
    textTransform: 'lowercase',
  },
})((props) => <Button {...props} />)
