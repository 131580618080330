import { makeStyles } from '@material-ui/core'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomTitle from '../../../../shared/CustomTitle'

const Screen02 = (props) => {
  const { handleScreenReset } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            <CustomTitle position="center">
              Félicitations ! <br />
              Tu connais désormais tout les secrets pour réussir une lettre de
              motivation parfaite.
            </CustomTitle>
            <CustomButton handleNext={handleScreenReset}>
              Étape suivante
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  wrapper: {
    width: ' 100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(3),
  },
  media: {
    height: '100%',
  },
  itemContainer: {
    height: '600px',
    width: '100%',
  },
  isSelected: {
    boxShadow:
      '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0.2), -5px 5px 15px rgba(0, 0, 0, 0.2), 5px -5px 15px rgba(0, 0, 0, 0.2)',
  },
  confirm: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
}))
