import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CustomAvatar from './CustomAvatar'

const PersonnalMessage = (props) => {
  const { friendName, content, seed } = props

  const classes = useStyles()
  return (
    <>
      <div className={classes.userDetailWrapper}>
        <Typography className={classes.userName}>{friendName}</Typography>
        <CustomAvatar seed={seed} />
      </div>
      <div className={classes.messageWrapper}>
        <Typography className={classes.messageContent}>{content}</Typography>
      </div>
    </>
  )
}

export default PersonnalMessage

const useStyles = makeStyles((theme) => ({
  root: {},
  userDetailWrapper: {
    marginTop: '0.75rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  userName: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '1.1&5',
    marginRight: '0.25rem',
  },
  messageWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  messageContent: {
    backgroundColor: '#0084FF',
    marginTop: '0.5rem',
    padding: '0.5rem',
    borderRadius: '0.5rem',
    fontSize: '14px',
    fontWeight: 700,
    color: 'white',
    textAlign: 'right',
    marginLeft: 'auto',
  },
}))
