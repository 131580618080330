import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'

import CustomButton from '../shared/CustomButton'
import CustomTitle from '../shared/CustomTitle'
import CustomText from '../shared/CustomText'
import ContentWrapper from '../shared/ContentWrapper'

const CustomFooterDialog = (props) => {
  const { dialOpen, handleDialClose } = props

  return (
    <div>
      <Dialog
        open={dialOpen}
        fullWidth
        maxWidth="lg"
        onClose={handleDialClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ContentWrapper>
          <Grid container justify="flex-end">
            <IconButton onClick={handleDialClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <CustomTitle>Mentions légales</CustomTitle>

          <Grid item xs={12} style={{ padding: '2rem' }}>
            <CustomTitle>Éditeur</CustomTitle>
            <CustomText>
              Le présent service est proposé par La Mission Locale du Bassin
              d’Emploi de Besançon Adresse du siège social : 10 C rue Midol -
              25044 - Besançon Cedex 
            </CustomText>
            <CustomText>
              Le directeur de la publication du présent site est le directeur de
              la Mission Locale de Besançon M. Thierry Grandmottet
            </CustomText>
          </Grid>

          <Grid item xs={12} style={{ padding: '2rem' }}>
            <CustomTitle>Hébergement</CustomTitle>
            <CustomText>
              L’hébergement du site est assuré par la société Zébulon Création –
              9E Rue Louis Pergaud - 25000 Besançon.
            </CustomText>
          </Grid>

          <Grid item xs={12} style={{ padding: '2rem' }}>
            <CustomTitle>Propriété intellectuelle</CustomTitle>
            <CustomText>
              Le site Internet, sa structure générale, ainsi que les textes,
              images animées ou non, savoir-faire, dessins, graphismes, etc. et
              tout autre élément composant le site, sont la propriété soit de la
              Mission Locale - Bassin d’emploi de Besançon soit de tiers qui
              auront autorisé leur reproduction, que ce soit de manière directe
              ou dans le respect de licences d’utilisation.
            </CustomText>
          </Grid>

          <Grid item xs={12} style={{ padding: '2rem' }}>
            <CustomTitle>Vie Privée et respect du RGPD</CustomTitle>
            <CustomText>
              Nous ne collectons pas vos données personnelles dans le cadre de
              l'application. Les informations renseignées dans le cadre du jeu
              (nom et âge) sont stockées uniquement dans le navigateur internet,
              elles sont supprimées à la fin de chaque partie et ne sont
              présentes qu'à titre d’indicatif pour le bon déroulement du jeu.
            </CustomText>
          </Grid>

          <Grid container justify="flex-end">
            <CustomButton handleNext={handleDialClose}>Fermer</CustomButton>
          </Grid>
        </ContentWrapper>
      </Dialog>
    </div>
  )
}

export default CustomFooterDialog
