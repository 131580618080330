import { useEffect, useState } from 'react'
import { Box, CardMedia, makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'
import { workHeaderCoverLetter, schoolHeaderCoverLetter } from '../content'

import Grid from '@material-ui/core/Grid'

import {
  workHeaderGood,
  workHeaderAverage,
  workHeaderBad,
  schoolHeaderGood,
  schoolHeaderAverage,
  schoolHeaderBad,
} from '../../../../images'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomButton from '../../../../shared/CustomButton'
import ContentWrapper from '../../../../shared/ContentWrapper'

import FsLightbox from 'fslightbox-react'

const Screen01 = (props) => {
  const {
    userWish,
    selectedIdx,
    setSelectedIdx,
    handleWorkHeaderChoice,
    handleSchoolHeaderChoice,
  } = props

  const classes = useStyles()

  const [toogler, setToogler] = useState(false)
  const [pictureLink, setPictureLink] = useState('')

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  const handlePreviewClick = (image) => {
    setPictureLink(getCurrentImage(image))
    setToogler(!toogler)
  }

  const getCurrentImage = (value) => {
    if (userWish === 'Emploi') {
      switch (value) {
        case 'good':
          return workHeaderGood
        case 'average':
          return workHeaderAverage
        case 'bad':
          return workHeaderBad
        default:
          break
      }
    }
    if (userWish === 'Formation') {
      switch (value) {
        case 'good':
          return schoolHeaderGood
        case 'average':
          return schoolHeaderAverage
        case 'bad':
          return schoolHeaderBad
        default:
          break
      }
    }
  }

  return (
    <div className={classes.appWrapper}>
      <CustomPaper>
        <ContentWrapper>
          <CustomTitle position="center">Quel est l'entête idéal ?</CustomTitle>

          <Box mb={2}>
            <CustomText position="center">
              Parmi ces 3 exemples, choisis la proposition qui selon toi
              correspond le mieux.
            </CustomText>
          </Box>

          <Grid
            container
            justify="space-around"
            direction="column"
            style={{ marginBottom: '1rem' }}
          >
            {userWish === 'Emploi' &&
              workHeaderCoverLetter.map(({ image }, idx) => (
                <Box mb={4} key={idx} onClick={() => setSelectedIdx(idx)}>
                  <Grid
                    className={`${classes.itemContainer} ${
                      selectedIdx === idx && classes.isSelected
                    }`}
                  >
                    <div
                      className={`${classes.buttonWrapper}
                  ${selectedIdx === idx && 'isSelected'}
                  `}
                    >
                      <Grid container direction="column">
                        <button
                          type="button"
                          className={classes.button}
                          onClick={() => handlePreviewClick(image)}
                        >
                          Aperçu
                        </button>
                        <button
                          type="button"
                          className={classes.button}
                          onClick={() => setSelectedIdx(idx)}
                        >
                          Sélectionner
                        </button>
                      </Grid>
                    </div>
                    <CardMedia
                      className={classes.media}
                      image={getCurrentImage(image)}
                      title={`curriculum-vitae-${idx}`}
                    />
                  </Grid>
                </Box>
              ))}
            {userWish === 'Formation' &&
              schoolHeaderCoverLetter.map(({ image }, idx) => (
                <Box mb={4} key={idx} onClick={() => setSelectedIdx(idx)}>
                  <Grid
                    className={`${classes.itemContainer} ${
                      selectedIdx === idx && classes.isSelected
                    }`}
                  >
                    <div
                      className={`${classes.buttonWrapper}
                  ${selectedIdx === idx && 'isSelected'}
                  `}
                    >
                      <Grid container direction="column">
                        <button
                          type="button"
                          className={classes.button}
                          onClick={() => handlePreviewClick(image)}
                        >
                          Aperçu
                        </button>
                        <button
                          type="button"
                          className={classes.button}
                          onClick={() => setSelectedIdx(idx)}
                        >
                          Sélectionner
                        </button>
                      </Grid>
                    </div>
                    <CardMedia
                      className={classes.media}
                      image={getCurrentImage(image)}
                      title={`curriculum-vitae-${idx}`}
                    />
                  </Grid>
                </Box>
              ))}
          </Grid>
          {userWish === 'Emploi' && (
            <CustomButton handleNext={handleWorkHeaderChoice}>
              Valider
            </CustomButton>
          )}
          {userWish === 'Formation' && (
            <CustomButton handleNext={handleSchoolHeaderChoice}>
              Valider
            </CustomButton>
          )}
        </ContentWrapper>
      </CustomPaper>
      <FsLightbox toggler={toogler} sources={[pictureLink]} />
    </div>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  appWrapper: {
    padding: '3rem 0',
    minHeight: '75vh',
  },
  media: {
    width: '100%',
    height: '100%',
  },
  itemContainer: {
    border: '2px solid #00000025',
    height: '100vh',
    maxHeight: '300px',
    width: '100%',
    position: 'relative',
  },
  starWrapper: {
    backgroundColor: '#efefef',
    padding: '0.15rem',
    margin: '0.5rem',
    borderRadius: '50%',
    border: '2px solid grey',
    position: 'absolute',
    right: 0,
    '&.isSelected': {
      borderColor: '#FFA41C',
    },
  },
  selectionIcon: {
    fontSize: '2.2rem',
    marginBottom: '-3px',
    color: theme.palette.common.black,
    '&.isSelected': {
      color: '#FFA41C',
    },
  },
  buttonWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.6)',
    borderRadius: '0.25rem',
    padding: '1.5rem 3rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button: {
    color: theme.palette.common.white,
    border: 'none',
    borderRadius: '0.25rem',
    background: theme.custom.answerColorHover,
    padding: '0.75rem 2rem',
    margin: '0.5rem 0',
    fontSize: '14px',
    fontWeight: 700,
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  isSelected: {
    border: '2px solid #c946e3',
    boxShadow:
      '5px 5px 15px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0.2), -5px 5px 15px rgba(0, 0, 0, 0.2), 5px -5px 15px rgba(0, 0, 0, 0.2)',
  },
}))
