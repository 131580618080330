import { makeStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'

import CustomPaper from '../../../../shared/CustomPaper'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomText from '../../../../shared/CustomText'
import CustomButton from '../../../../shared/CustomButton'
import { useEffect } from 'react'

const Screen03 = (props) => {
  const { handleScreenReset, trainingQuizz, setTrainingQuizz } = props

  const classes = useStyles()

  useEffect(() => {
    setTrainingQuizz({
      ...trainingQuizz,
      lifes: getTriesForNextQuizz(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTriesForNextQuizz = () => {
    if (trainingQuizz.totalScore >= 90) {
      return 4
    } else if (
      trainingQuizz.totalScore < 90 &&
      trainingQuizz.totalScore >= 65
    ) {
      return 3
    } else if (
      trainingQuizz.totalScore < 65 &&
      trainingQuizz.totalScore >= 40
    ) {
      return 2
    } else {
      return 1
    }
  }

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            <Box mt={2}>
              <CustomTitle position="center">Félicitations!</CustomTitle>
            </Box>
            <Box mb={3}>
              <CustomText position="center">
                Vous avez obtenu un score de :{' '}
                <span className={classes.highlightSpan}>
                  {trainingQuizz.totalScore}
                </span>
              </CustomText>
            </Box>
            <Box mb={3}>
              <CustomText position="center">
                Vous bénéficiez de{' '}
                <span className={classes.highlightSpan}>
                  {getTriesForNextQuizz()}
                </span>{' '}
                {getTriesForNextQuizz() < 2
                  ? 'point d’embauche'
                  : 'points d’embauche'}{' '}
                pour la prochaine étape.
              </CustomText>
            </Box>
            <CustomText position="center">Bonne chance</CustomText>
            <CustomButton handleNext={handleScreenReset}>
              Passer à l’entretien
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen03

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  highlightSpan: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}))
