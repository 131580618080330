import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import './App.css'
import { ThemeProvider } from '@material-ui/core'
import { useState } from 'react'
import lightTheme from './themes/lightTheme'
import darkTheme from './themes/darkTheme'

function App() {
  const [toogleTheme, setToogleTheme] = useState(false)
  return (
    <ThemeProvider theme={!toogleTheme ? lightTheme : darkTheme}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Home toogleTheme={toogleTheme} setToogleTheme={setToogleTheme} />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
