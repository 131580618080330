import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'

import CustomButton from '../../../../shared/CustomButton'
import phoneSkeleton from '../../../../images/smartphone_short.svg'
import GeneralMessage from '../../../../shared/GeneralMessage'

import { phoneBg } from '../../../../images'

const Screen01 = (props) => {
  const { handleScreenNext, setInterviewDate, userInfo } = props

  const classes = useStyles()

  const [bossDial, setBossDial] = useState('')
  const [anim1, setAnim1] = useState('')
  const [btn1, setBtn1] = useState('')
  const [btn2, setBtn2] = useState('')
  const [btn3, setBtn3] = useState('')

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
    setTimeout(() => setAnim1('visible'), 500)
    setTimeout(() => setBtn1('visible'), 1500)
    setTimeout(() => setBtn2('visible'), 2500)
  }, [])

  const handleFirstChoiceClick = () => {
    setInterviewDate('Mardi')
    setBtn2('')
    setTimeout(() => setBtn1(''), 500)
    setTimeout(() => setAnim1(''), 1000)
    setTimeout(() => setBossDial('Parfait ! alors à Mardi 9H30'), 2000)
    setTimeout(() => setAnim1('visible'), 3000)
    setTimeout(() => setBtn3('visible'), 4000)
  }

  const handleSecondChoiceClick = () => {
    setInterviewDate('Jeudi')
    setBtn2('')
    setTimeout(() => setBtn1(''), 500)
    setTimeout(() => setAnim1(''), 1000)
    setTimeout(() => setBossDial('Parfait ! alors à Jeudi 9H30'), 2000)
    setTimeout(() => setAnim1('visible'), 3000)
    setTimeout(() => setBtn3('visible'), 4000)
  }

  const getTextForMessageContent = () => {
    if (userInfo.wish === 'Emploi') {
      return 'un emploi'
    } else if (userInfo.wish === 'Formation') {
      return 'une formation'
    }
  }

  return (
    <div className={classes.root}>
      <div style={{ position: 'relative' }}>
        <div className={classes.phoneBG} />
        <div className={classes.phoneSkeleton}>
          <div>
            <div className={classes.animWrapper}>
              <div className={`${classes.message01} ${anim1}`}>
                <GeneralMessage
                  seed={userInfo.bossAvatar}
                  friendName="Patron"
                  content={
                    bossDial !== ''
                      ? bossDial
                      : `Bonjour je me permets de vous contacter car j'ai vu votre profil sur LinkedIn, j'ai pu constater que vous recherchiez ${getTextForMessageContent()}, et votre profil nous intéresse. Je serais ravi de vous rencontrer Mardi à 9h30 pour un entretien afin d'échanger ensemble, seriez-vous disponible ?`
                  }
                />
              </div>
            </div>

            <div className={classes.animWrapper}>
              <div className={`${classes.btn01} ${btn1}`}>
                <CustomButton handleNext={handleFirstChoiceClick}>
                  Mardi me convient parfaitement
                </CustomButton>
              </div>
            </div>

            <div className={classes.animWrapper}>
              <div className={`${classes.btn01} ${btn2}`}>
                <CustomButton full={true} handleNext={handleSecondChoiceClick}>
                  Je serais disponible que Jeudi
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.animBtnWrapper}>
        <div className={`${classes.btn01} ${btn3}`}>
          <CustomButton handleNext={handleScreenNext}>Suivant</CustomButton>
        </div>
      </div>
    </div>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    minHeight: '75vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneBG: {
    position: 'absolute',
    content: "''",
    top: '70px',
    left: '0',
    width: '350px',
    height: '480px',
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6)100%),  url(${phoneBg})`,
    backgroundColor: '#ffffff',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: '90%',
  },
  phoneSkeleton: {
    width: '350px',
    height: '650px',
    backgroundImage: `url(${phoneSkeleton})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    position: 'relative',
    '& > div': {
      padding: '105px 40px',
    },
  },
  animWrapper: {
    overflow: 'hidden',
  },
  animBtnWrapper: {
    marginTop: '2rem',
    overflow: 'hidden',
  },
  message01: {
    transform: 'translateX(-300px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
  btn01: {
    width: '100%',
    transform: 'translateY(50px)',
    transition: 'transform 0.5s ease',
    '&.visible': {
      transform: 'translateX(0)',
    },
  },
}))
