import { createMuiTheme } from '@material-ui/core'

const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#111827',
    },
  },
  typography: {
    fontFamily: "'Quicksand', sans-serif",
  },
  custom: {
    appBgColor: '#F9FAFB',
    paperBgColor: '#FFFFFF',
    titleColor: '#111827',
    textColor: '#000000',
    answerColorHover:
      'linear-gradient(90deg, rgba(135,7,248,1) 0%, rgba(235,13,248,1) 100%)',
  },
})

export default lightTheme
