export const jobTips = [
  {
    label: 'Mission Locale',
    id: 'mission-locale',
    size: 'xl',
    definition:
      'La Mission Locale du Bassin d’Emploi de Besançon accueille, informe, oriente accompagne tous les jeunes de 16 à 25 ans, notamment ceux sortis du système scolaire, en construisant avec eux, leurs parcours personnalisés vers l’emploi, la formation et l’autonomie.',
    link: 'https://missionlocale-besancon.fr/',
    linkName: 'missionlocale-besancon.fr',
  },
  {
    label: 'Pole Emploi',
    id: 'pole-emploi',
    size: 'xl',
    definition:
      "Pôle emploi est un établissement public à caractère administratif, chargé de l'emploi en France.<br /><br />Pôle emploi a trois tâches essentielles : l'accompagnement au retour à l'emploi, l'indemnisation des demandeurs d'emploi, par le biais de l'allocation d'aide au retour à l'emploi (dite ARE) ou de l'allocation de solidarité spécifique (dite ASS), la mise en relation des entreprises avec les candidats demandeurs d'emploi et bien d'autres…",
    link: 'https://www.pole-emploi.fr/accueil/',
    linkName: 'pole-emploi.fr',
  },
  {
    label: 'Intérim',
    id: 'interim',
    size: 'md',
    definition:
      "L’intérim (ou travail intérimaire ou travail temporaire) consiste à mettre à disposition provisoire d’entreprises clientes, des salariés qui, en fonction d'une rémunération convenue, sont embauchés et rémunérés à cet effet par l'entreprise de travail temporaire.<br /><br />Elle se caractérise donc par une relation triangulaire entre l’entreprise de travail temporaire, l’entreprise cliente et le salarié, et implique la conclusion de deux contrats : un contrat de mise à disposition (entre l’entreprise de travail temporaire et l’entreprise cliente) et un contrat de mission (entre l’entreprise de travail temporaire et le salarié).<br /><br />Il existe plusieurs agences d’intérim, voici quelques exemples sur Besançon :<ul><li><strong>Agence intérim Synergie Besançon</strong></li><li><strong>Agence d'Intérim Manpower Besançon</strong></li><li><strong>SUP Interim Besançon</strong></li><li><strong>Temporis</strong></li><li><strong>Randstad Besançon</strong></li></ul>",
    link: 'https://www.google.fr/search?q=interim',
    linkName: 'google.fr/interim',
  },
  {
    label: 'Indeed',
    id: 'indeed',
    size: 'xl',
    definition:
      "Indeed est un métamoteur (qui puise ses informations à travers plusieurs moteurs de recherche généralistes) de recherche d'emploi.<br /><br/>Il existe également d’autres moteurs de recherche d’emploi :<br /><ul><li><strong>monster.fr</strong></li><li><strong>Jooble</strong></li><li><strong>OptionCarriere</strong></li><li><strong>Jobintree</strong></li><li><strong>JobiJoba</strong></li></ul>",
    link: 'https://fr.indeed.com/',
    linkName: 'indeed.fr',
  },
  {
    label: 'Onisep',
    id: 'onisep',
    size: 'sm',
    definition:
      "L'Onisep (Office national d'information sur les enseignements et les professions) est un opérateur de l’État qui relève du ministère de l’Éducation nationale et de la Jeunesse et du ministère de l’Enseignement supérieur, de la Recherche et de l’Innovation. Éditeur public, l'Onisep produit et diffuse toute l'information sur les formations et les métiers. Il propose aussi des services aux élèves, aux parents et aux équipes éducatives.",
    link: 'https://www.onisep.fr/',
    linkName: 'onisep.fr',
  },
]

export const schoolTips = [
  {
    label: 'Mission Locale',
    id: 'mission-locale',
    size: 'xl',
    definition:
      'La Mission Locale exerce une mission de service public de proximité afin de permettre à tous les jeunes de 16 à 25 ans de surmonter les difficultés qui font obstacle à leur insertion professionnelle et sociale.<br /><br />Les missions locales font partie du service public de l’emploi et entretiennent des relations privilégiées avec Pôle emploi dans le cadre d’un partenariat renforcé. Elles s’appuient sur des dispositifs mis en place par l’Etat et les collectivités territoriales.',
    link: 'https://missionlocale-besancon.fr/',
    linkName: 'missionlocale-besancon.fr',
  },
  {
    label: 'Emfor',
    id: 'emfor',
    size: 'md',
    definition:
      'Emfor Bourgogne-Franche-Comté est un Groupement d’intérêt public issu de la volonté commune de l’État, de la Région Bourgogne-Franche-Comté et des organisations professionnelles d’employeurs et de salariés de disposer d’un centre de ressources pour accompagner les acteurs de l’emploi, de la formation et de l’orientation professionnelle sur les territoires. Son activité se construit autour de trois grandes fonctions : informer, animer-professionnaliser, observer.<br /><br />Il fait partie du réseau des Carif-Oref.',
    link: 'https://www.emfor-bfc.org/formation/',
    linkName: 'emfor-bfc.org',
  },
  {
    label: 'CRIJ',
    id: 'crij',
    size: 'md',
    definition:
      "Le Centre Régional d'Information Jeunesse permet aux jeunes de s’informer sur l'emploi, la formation, les loisirs et le sport, la santé, l'international, le service civique et l'aide aux projets.",
    link: 'https://www.decouvrirlemonde.jeunes.gouv.fr/reseaux-accompagnement/structure/crij-bourgogne-franche-comte-site-de-besancon',
    linkName: 'crij-bfc.gouv.fr',
  },
  {
    label: 'La bonne formation',
    id: 'la-bonne-formation',
    size: 'md',
    definition:
      "La Bonne Formation est un outil de Pôle Emploi. Il permet de repérer les formations les plus performantes, des solutions de financement et identifier des débouchés pour mieux accompagner les personnes voulant évoluer professionnellement.<br /><br />Avec La Bonne Formation accédez :<ul><li>à l’ensemble des formations France entière, regroupant les catalogues de formations accessibles aux demandeurs d'emploi.</li><li>aux données sur l’efficacité de l’ensemble des domaines de formation par région</li></ul>",
    link: 'https://labonneformation.pole-emploi.fr/toutes-les-formations/besancon-25',
    linkName: 'labonneformation.pole-emploi.fr',
  },
  {
    label: 'Cléor',
    id: 'cleor',
    size: 'md',
    definition:
      "Cléor est un outil développé par les régions Bretagne, Centre-Val de Loire et Bourgogne Franche-Comté et leurs Carif-Oref associés. Il recense tous les métiers qui existent en France, et permet de faire une recherche de formation par centres d'intérêts, compétences, secteur d'activité.",
    link: 'https://bourgogne-franche-comte.cleor.org/',
    linkName: 'bourgogne-franche-comte.cleor.org',
  },
]
