import { makeStyles, Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

import milo from '../../images/logos/logo-milo.svg'
import oln from '../../images/logos/logo-oln.png'

import CustomTitle from '../../shared/CustomTitle'

const Header = (props) => {
  const classes = useStyles()

  const resetSeriousGame = () => {
    sessionStorage.setItem(
      'userInfo',
      JSON.stringify({
        name: '',
        age: -1,
        wish: '',
        myAvatar: Math.floor(Math.random() * 5000),
        friendAvatar: Math.floor(Math.random() * 5000),
        bossAvatar: Math.floor(Math.random() * 5000),
      })
    )
    sessionStorage.setItem(
      'trainingQuizz',
      JSON.stringify({
        currentQuestion: 0,
        totalScore: 0,
        lifes: 0,
      })
    )
    sessionStorage.setItem(
      'interviewQuizz',
      JSON.stringify({
        currentQuestion: 0,
      })
    )
    sessionStorage.setItem('currentScreen', '0')
    sessionStorage.setItem('currentStep', '0')
    window.location.reload()
  }

  return (
    <Grid item className={classes.root}>
      <Grid item className={classes.navContainer}>
        <Grid item>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            style={{ padding: '0 1rem' }}
          >
            <Grid item className={classes.imgWrapper}>
              <a href="https://www.missionlocale-besancon.fr/">
                <img
                  className={classes.img}
                  src={milo}
                  alt="Logo mission locale Besançon"
                />
              </a>
            </Grid>
            <Grid item className={classes.imgWrapper}>
              <img
                className={classes.img}
                src={oln}
                alt="Logo mission locale Besançon"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            padding: '0 1rem',
          }}
        >
          <Tooltip arrow={true} title="Réinitialiser le jeu">
            <IconButton
              className={classes.restartIcon}
              onClick={() => resetSeriousGame()}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item>
        <CustomTitle position="center">Tut'emploi</CustomTitle>
      </Grid>
    </Grid>
  )
}

export default Header

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.custom.appBgColor,
  },
  navContainer: {
    padding: '0 0 1.5rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  themeIcon: {
    color: theme.custom.titleColor,
  },
  imgWrapper: {
    width: '100%',
    maxWidth: '225px',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  restartIcon: {
    background: theme.custom.answerColorHover,
    color: theme.palette.common.white,
  },
}))
