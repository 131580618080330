import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'
import Screen03 from './screens/Screen03'

const Step07 = (props) => {
  const {
    screen,
    setScreen,
    userInfo,
    handleStepNext,
    trainingQuizz,
    setTrainingQuizz,
  } = props

  const handleScreenNext = () => {
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenBack = () => {
    setScreen((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentScreen', screen - 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  return (
    <>
      <Container>
        <Step07Stepper
          handleScreenNext={handleScreenNext}
          handleScreenBack={handleScreenBack}
          screen={screen}
        >
          <Step07Screen>
            <Grid item>
              <Screen01
                userInfo={userInfo}
                handleScreenNext={handleScreenNext}
              />
            </Grid>
          </Step07Screen>
          <Step07Screen>
            <Grid item>
              <Screen02
                userInfo={userInfo.wish}
                trainingQuizz={trainingQuizz}
                setTrainingQuizz={setTrainingQuizz}
                handleScreenNext={handleScreenNext}
              />
            </Grid>
          </Step07Screen>
          <Step07Screen>
            <Grid item>
              <Screen03
                userInfo={userInfo}
                trainingQuizz={trainingQuizz}
                setTrainingQuizz={setTrainingQuizz}
                handleScreenReset={handleScreenReset}
              />
            </Grid>
          </Step07Screen>
        </Step07Stepper>
      </Container>
    </>
  )
}

const Step07Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <>{currentChild}</>
}

const Step07Screen = ({ children }) => {
  return <>{children}</>
}

export default Step07
