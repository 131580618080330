import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

const CustomProgress = (props) => {
  const { timeLeft, progress } = props

  const classes = useStyles()
  return (
    <Box my={3}>
      <Typography>
        <small>Temps restant: {timeLeft}sec</small>
      </Typography>
      <LinearProgress
        className={classes.progressBar}
        variant="determinate"
        value={progress}
      />
    </Box>
  )
}

export default CustomProgress

const useStyles = makeStyles((theme) => ({
  progressBar: {
    padding: '1px',
    borderRadius: '20px',
    '& .MuiLinearProgress-bar': {
      background:
        'linear-gradient(90deg, rgba(135,7,248,1) 0%, rgba(235,13,248,1) 100%)',
    },
  },
}))
