import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'
import Screen03 from './screens/Screen03'

const Step06 = (props) => {
  const {
    screen,
    setStep,
    setScreen,
    userInfo,
    handleStepNext,
    trainingQuizz,
    setTrainingQuizz,
    interviewQuizz,
    setInterviewQuizz,
    resetSeriousGame,
  } = props

  const handleScreenNext = () => {
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenBack = () => {
    setScreen((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentScreen', screen - 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  return (
    <>
      <Container>
        <Step06Stepper
          handleScreenNext={handleScreenNext}
          handleScreenBack={handleScreenBack}
          screen={screen}
        >
          <Step06Screen>
            <Grid item>
              <Screen01
                userInfo={userInfo}
                handleScreenNext={handleScreenNext}
              />
            </Grid>
          </Step06Screen>
          <Step06Screen>
            <Grid item>
              <Screen02
                userInfo={userInfo}
                setStep={setStep}
                setScreen={setScreen}
                trainingQuizz={trainingQuizz}
                setTrainingQuizz={setTrainingQuizz}
                interviewQuizz={interviewQuizz}
                setInterviewQuizz={setInterviewQuizz}
                handleScreenNext={handleScreenNext}
              />
            </Grid>
          </Step06Screen>
          <Step06Screen>
            <Grid item>
              <Screen03
                userInfo={userInfo}
                interviewQuizz={interviewQuizz}
                handleScreenReset={handleScreenReset}
                resetSeriousGame={resetSeriousGame}
              />
            </Grid>
          </Step06Screen>
        </Step06Stepper>
      </Container>
    </>
  )
}

const Step06Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <>{currentChild}</>
}

const Step06Screen = ({ children }) => {
  return <>{children}</>
}

export default Step06
