export const workQuizz = [
  {
    id: 'q_01',
    time: 30,
    question: 'Parlez-moi de vous ?',
    answers: [
      {
        id: 'q_01_r_01',
        content: 'Alors, j’ai pas d’expérience mais je suis motivé car on m’a dit qu’on gagnait beaucoup d’argent chez vous, en plus je suis assez à l’heure et je m’entend bien avec presque tout le monde.',
        correct: false,
        level: 'bad',
        popup: 'On a toujours un peu d’expérience d’une façon ou d’une autre ! N’être motivé que par l’argent et les approximations vous vaudra un recalage  immédiat'
      },
      {
        id: 'q_01_r_02',
        content: 'J’ai eu quelques expériences à gauche à droite, mais je veux toujours en savoir plus et je suis motivé pour rentrer dans votre entreprise pour de nombreuses raisons que je vous exposerai après',
        correct: false,
        level: 'average',
        popup: 'Pas mal mais il vaut mieux répondre tout de suite à la question que de répondre de façon vague !'
      },
      {
        id: 'q_01_r_03',
        content: 'J’ai plusieurs expériences professionnelles qui m’ont appris de nombreuses choses, aujourd’hui je suis très motivé à l’idée de rejoindre votre entreprise  car j’y vois une occasion contribuer à un projet qui m’intéresse.',
        correct: true,
        level: 'good',
        popup: 'Bonne réponse, contribuer à l’entreprise et à son projet, être très motivé et avoir de l’expérience sont d’excellents arguments'
      },
    ]
  },
  {
    id: 'q_02',
    time: 30,
    question: 'Quels sont vos faiblesses et vos points forts ?',
    answers: [
      {
        id: 'q_02_r01',
        content: "J’ai parfois des problèmes de réveil, mais en général je bosse dur quand je suis motivé et que je ne suis pas trop fatigué. Mon plus gros point fort c’est que je suis super cool avec les gens.",
        correct: false,
        level: 'bad',
        popup: "Des problèmes de réveil, motivé uniquement quand pas fatigué… Vous vous engageriez vous-même à ce niveau là ?!"
      },
      {
        id: 'q_02_r02',
        content: "Ma plus grande faiblesse c’est le manque de confiance en moi, mais je compense avec ma capacité à savoir demander de l’aide quand il le faut, je suis aussi quelqu’un d’amical.",
        correct: false,
        level: 'average',
        popup: "Manquer de confiance en soit peut être un critère déterminant pour un emploi, être amical n’est pas suffisant comme qualité."
      },
      {
        id: 'q_02_r_03',
        content: "En point faible je dirais que je suis quelqu’un de perfectionniste, cela à tendance à me pousser à être exigeant avec les autres, en point fort je pense être quelqu’un de fiable et un élément moteur lors des travaux en équipe.",
        correct: true,
        level: 'good',
        popup: "Avoir un point faible qui est également une qualité est une bonne chose, attention à ne pas être caricatural ! La fiabilité et être élément moteur sont des gages de qualités intéressants pour l’employeur"
      },
    ]
  },
  {
    id: 'q_03',
    time: 30,
    question: 'Pourquoi pensez-vous être fait pour ce poste et en quoi vous intéresse-t-il ?',
    answers: [
      {
        id: 'q_03_r01',
        content: "Je suis le meilleur candidat que vous aurez parce que moi j’en veux vraiment, alors finalement le choix est facile non ? Si vous prenez quelqu’un d'autre, vous allez rater une super occasion.",
        correct: false,
        level: 'bad',
        popup: 'PAF ! Ah je crois que vos chevilles ont éclaté, ne vous surestimez pas et ne prenez pas votre employeur pour un imbécile !'
      },
      {
        id: 'q_03_r02',
        content: "Je suis un peu attiré par un travail comme celui-ci, j’ai des expériences qui seront peut être utiles, je sais que cela prendra du temps mais cela sera aussi une expérience pas mal formatrice pour moi.",
        correct: false,
        level: 'average',
        popup: "Hum… Un peu attiré, peut être utile, pas mal formatrice. Votre employeur vous embauchera peut être un peu alors ? Trop approximatif !"
      },
      {
        id: 'q_03_r_03',
        content: "Par mes expériences précédentes et mes compétences, je pense être en mesure de mener toutes les missions de ce poste. Cela me permettra de concrétiser mes objectifs et d’être le nouvel élément moteur de votre entreprise. ",
        correct: true,
        level: 'good',
        popup: "Votre employeur appréciera votre volonté d’avoir des objectifs ET de vous investir dans l’entreprise"
      },
    ]
  },
  {
    id: 'q_04',
    time: 30,
    question: 'Que savez-vous de notre entreprise ?',
    answers: [
      {
        id: 'q_04_r_01',
        content: "Pas grand chose, mais on m’a dit que vous payez bien, donc j’suis là, vous faites des bons trucs j’crois.",
        correct: false,
        level: 'bad',
        popup: "Approximatif, pas de connaissances, langage à la limite du grossier, Offre d’emploi loupée !"
      },
      {
        id: 'q_04_r_02',
        content: "J’ai vu que vous aviez un site et je sais que vos produits sont bien car j’ai plein d’amis qui m’en ont parlé.",
        correct: false,
        level: 'average',
        popup: "Pas assez de prise de renseignements,  avoir des amis qui “en parlent” ne suffit pas pour en savoir plus"
      },
      {
        id: 'q_04_r_03',
        content: "Je me suis renseigné sur votre site, je connais également bien vos produits, qui sont à mon sens, les plus attractifs du marché et dont j'ai déjà fait la promotion auprès de mes amis.",
        correct: true,
        level: 'good',
        popup: "Une bonne reconnaissance de l’entreprise et de ses activités est un gage de confiance pour l’employeur"
      },
    ]
  },
  {
    id: 'q_05',
    time: 30,
    question: 'Où vous voyez-vous dans 5 ans ?',
    answers: [
      {
        id: 'q_05_r_01',
        content: "Bah ça dépend si on me propose mieux ça sera pas chez vous c’est sûr ! Mais sinon ben à un poste mieux payé quoi, faut bien que je parte en vacances !",
        correct: false,
        level: 'bad',
        popup: "Vous ne voulez vraiment pas ce travail non ? Approximatif, dénigrant et aucune garantie pour l’employeur, vous allez faire un malheur..."
      },
      {
        id: 'q_05_r_02',
        content: "Je ne sais trop, mais j’espère être toujours là et faire du bon travail.",
        correct: false,
        level: 'average',
        popup: "C’est pas mal, mais l’employeur serait plus rassuré à l’idée si vous êtes capables de vous projeter dans le futur"
      },
      {
        id: 'q_05_r_03',
        content: "J’espère être devenu un élément moteur dans l'entreprise et si possible obtenir une évolution en interne à la hauteur de mes résultats et compétences.",
        correct: true,
        level: 'good',
        popup: "La volonté de devenir un élément indispensable à l’entreprise sera bien accueillie, un peu d’ambition selon le poste peut jouer en votre faveur également"
      },
    ]
  },
  {
    id: 'q_06',
    time: 30,
    question: "Avez-vous des questions  ?",
    answers: [
      {
        id: 'q_06_r_01',
        content: "C’est quel jour la paye ? À qui il faut envoyer les arrêts maladie ?",
        correct: false,
        level: 'bad',
        popup: "Question centrée sur l’argent et la possibilité d’être absent, vous allez faire peur à votre employeur. Recalé !"
      },
      {
        id: 'q_06_r_02',
        content: "Je n’ai pas de questions, merci.",
        correct: false,
        level: 'average',
        popup: "Il peut arriver que l’on n’ait pas de questions, mais il est toujours bon d’en avoir une quand même pour prouver votre intérêt pour ce poste, évitez de demander les mêmes informations !"
      },
      {
        id: 'q_06_r_03',
        content: "Quelles sont les perspectives d’évolution sur le poste ?",
        correct: true,
        level: 'good',
        popup: "Une question qui montre à l’employeur votre envie de vous projeter dans l’avenir, un bon choix"
      },
    ]
  },
]

export const schoolQuizz = [
  {
    id: 'q_01',
    time: 30,
    question: 'Parlez-moi de vous ?',
    answers: [
      {
        id: 'q_01_r_01',
        content: 'J’suis motivé car on m’a dit que cette formation était super cool et qu’on y gagnait plein d’argent à la fin.',
        correct: false,
        level: 'bad',
        popup: 'N’être motivé que par l’argent et un langage trop familier vous vaudra un recalage  immédiat'
      },
      {
        id: 'q_01_r_02',
        content: 'Je suis plutôt motivé pour rentrer dans votre formation car on me l’a un peu recommandée pour un bon apprentissage.',
        correct: false,
        level: 'average',
        popup: 'Pas mal mais trop approximatif !'
      },
      {
        id: 'q_01_r_03',
        content: 'Aujourd’hui je suis très motivé à l’idée de rejoindre  votre formation pour un apprentissage  car j’y vois une vraie occasion de peaufiner mes compétences et de contribuer à un projet qui m’intéresse.',
        correct: true,
        level: 'good',
        popup: 'Bonne réponse, se projeter dans un futur emploi post-formation, être très motivé et avoir de premières sont d’excellents arguments'
      },
    ]
  },
  {
    id: 'q_02',
    time: 30,
    question: 'Quels sont vos faiblesses et vos points forts ?',
    answers: [
      {
        id: 'q_02_r01',
        content: "J’ai parfois des problèmes de réveil, mais en général je bosse dur quand je suis motivé et que je ne suis pas trop fatigué. Mon plus gros point fort c’est que je suis super cool avec les gens.",
        correct: false,
        level: 'bad',
        popup: "Des problèmes de réveil, motivé uniquement quand pas fatigué… Vous vous engageriez vous-même à ce niveau là ?!"
      },
      {
        id: 'q_02_r02',
        content: "Ma plus grande faiblesse c’est le manque de confiance en moi, mais je compense avec ma gentillesse.",
        correct: false,
        level: 'average',
        popup: "Manquer de confiance en soit peut être un critère déterminant une entrée en formation, être gentil n’est pas suffisant comme qualité."
      },
      {
        id: 'q_02_r_03',
        content: "En point faible je dirais que je suis quelqu’un de trop perfectionniste ça me prend trop de  temps parfois, en point fort je pense être quelqu’un de patient et fiable.",
        correct: true,
        level: 'good',
        popup: "Avoir un point faible qui est également une qualité est une bonne chose, attention à ne pas être caricatural ! La fiabilité et la patience sont des qualités intéressantes pour un apprentissage"
      },
    ]
  },
  {
    id: 'q_03',
    time: 30,
    question: 'Que savez vous du métier sur lequel vous allez être formé ?',
    answers: [
      {
        id: 'q_03_r01',
        content: "Bah ça fatigue pas mal, mais c’est cool et la paye est ok donc moi ça me va.",
        correct: false,
        level: 'bad',
        popup: 'Fatigué d’avance, langage très familier et peu explicité, c’est raté !'
      },
      {
        id: 'q_03_r02',
        content: "Je trouve les concepts de ce métier pas trop mal, je trouve qu’il me correspond un petit peu et  j’imagine bien y faire carrière si j’aime vraiment après.",
        correct: false,
        level: 'average',
        popup: "Trop approximatif ! Pas trop mal, Un petit peu, si j’aime vraiment, cela ne rassurera pas votre futur organisme de formation !"
      },
      {
        id: 'q_03_r_03',
        content: "C’est un métier qui me passionne, j’ai consulté de nombreuses documentations sur le sujet et j’ai même pu effectuer un stage pour m’assurer de mon choix.",
        correct: true,
        level: 'good',
        popup: "Vous avez fait des recherches, un stage et avez confirmé votre projet, c’est parfait !"
      },
    ]
  },
  {
    id: 'q_04',
    time: 30,
    question: 'Connaissez-vous le secteur d’activité qui recrute à l’issue de la formation ?',
    answers: [
      {
        id: 'q_04_r_01',
        content: "Pas grand chose, mais on m’a dit que ça payait bien, donc j’suis venu.",
        correct: false,
        level: 'bad',
        popup: "Vous avez vu la lumière et vous êtes entré ? Un apprentissage c’est du sérieux ! L’argent ne doit pas être votre unique moteur"
      },
      {
        id: 'q_04_r_02',
        content: "J’ai lu quelques articles sur le sujet et on m’a dit qu’il est en pleine expansion alors on m’a dit d’y aller.",
        correct: false,
        level: 'average',
        popup: "Pas terrible ! le “on m’a dit” peut montrer que vous n’êtes pas capable de décider par vous même et peut poser question quand à votre envie réelle !"
      },
      {
        id: 'q_04_r_03',
        content: "Je me suis renseigné sur divers sites et brochures, je connais également bien les évolutions récentes qui offrent de nombreuses perspectives d’avenir.",
        correct: true,
        level: 'good',
        popup: "Vous vous êtes bien renseigné et vous savez où vous mettez les pieds, organisme de formation rassuré !"
      },
    ]
  },
  {
    id: 'q_05',
    time: 30,
    question: 'Où vous voyez-vous dans 5 ans ?',
    answers: [
      {
        id: 'q_05_r_01',
        content: "Bah ça dépend si je trouve une formation dans un autre secteur,  ça ne sera pas chez vous ! Mais sinon à un poste en cdi et bien payé.",
        correct: false,
        level: 'bad',
        popup: "Vous ne voulez vraiment pas de cette formation non ? Approximatif, dénigrant et aucune garantie pour l’organisme de formation, vous allez faire un malheur..."
      },
      {
        id: 'q_05_r_02',
        content: "Je ne sais trop, mais si ma formation se passe bien et que j’aime encore, j’espère avoir un travail.",
        correct: false,
        level: 'average',
        popup: "Avant même de commencer vous partez défaitiste ! On attend de la volonté de votre part pour un apprentissage, ce n’est pas intéressant pour votre organisme de formation de former une personne qui n’est pas sûre d’elle."
      },
      {
        id: 'q_05_r_03',
        content: "J’espère être recruté à l'issue de ma formation afin de devenir un élément moteur dans une entreprise et si possible obtenir une évolution en interne à la hauteur de mes résultats et compétences.",
        correct: true,
        level: 'good',
        popup: "La volonté de devenir un élément indispensable à l’entreprise sera bien accueillie, un peu d’ambition et l’envie de continuer avec l’entreprise sera également bien perçue"
      },
    ]
  },
  {
    id: 'q_06',
    time: 30,
    question: "Avez-vous des questions  ?",
    answers: [
      {
        id: 'q_06_r_01',
        content: "C’est une formation rémunérée ? C’est quoi la tolérance des retards le matin ?",
        correct: false,
        level: 'bad',
        popup: "Question centrée sur l’argent et la possibilité d’arriver en retard, vous allez faire peur à votre recruteur. Recalé !"
      },
      {
        id: 'q_06_r_02',
        content: "Je n’ai pas de questions, merci...",
        correct: false,
        level: 'average',
        popup: "Il peut arriver que l’on n’ait pas de questions, mais il est toujours bon d’en avoir une ou deux pour prouver votre intérêt pour cette formation, évitez tout de même de répéter les mêmes questions !"
      },
      {
        id: 'q_06_r_03',
        content: "Quelles sont les perspectives de recrutement suite à ma formation ?",
        correct: true,
        level: 'good',
        popup: "Une question qui montre au recruteur votre envie de vous projeter dans l’avenir, un bon choix"
      },
    ]
  },
]

