import { makeStyles } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

const CustomPaper = (props) => {
  const { children } = props

  const classes = useStyles()
  return (
    <Paper variant="outlined" className={classes.root}>
      {children}
    </Paper>
  )
}

export default CustomPaper

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.custom.paperBgColor,
  },
}))
