import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'

import ContentWrapper from './ContentWrapper'
import CustomTitle from './CustomTitle'
import CustomText from './CustomText'
import CustomButton from './CustomButton'

const CustomInterviewDialog = (props) => {
  const {
    dialogOpen,
    handleDialogClose,
    restartJobQuizz,
    trainingQuizz,
    handleQuizzNext,
    currentQuestionIndex,
    questions,
    dialContent,
    setIsPaused,
  } = props

  const handleClickSubmitAnswer = () => {
    if (trainingQuizz.lifes <= 0) {
      restartJobQuizz()
      setIsPaused(false)
    } else {
      handleDialogClose()
      handleQuizzNext()
    }
  }

  return (
    <div>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ContentWrapper>
          <CustomTitle>{dialContent.title}</CustomTitle>
          <CustomText id="alert-dialog-description">
            {dialContent.text}
          </CustomText>
          <Grid container justify="flex-end">
            <CustomButton handleNext={handleClickSubmitAnswer}>
              {trainingQuizz.lifes <= 0
                ? 'Valider'
                : currentQuestionIndex === questions.length - 1
                ? 'Suivant'
                : 'Question suivante'}
            </CustomButton>
          </Grid>
        </ContentWrapper>
      </Dialog>
    </div>
  )
}

export default CustomInterviewDialog
