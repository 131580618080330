import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import CustomButton from '../shared/CustomButton'
import CustomTitle from '../shared/CustomTitle'
import CustomText from '../shared/CustomText'
import ContentWrapper from '../shared/ContentWrapper'

const CustomFooterDialog = (props) => {
  const { dialOpen, handleDialClose } = props

  return (
    <div>
      <Dialog
        open={dialOpen}
        fullWidth
        maxWidth="lg"
        onClose={handleDialClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ContentWrapper>
          <Grid container justify="flex-end">
            <IconButton onClick={handleDialClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <CustomTitle>Crédits</CustomTitle>
          <CustomText>
            L’ensemble de la conception et du design du jeu a été réalisé dans
            le cadre de l’action Osez le numérique organisée par la Mission
            locale du bassin d’emploi de Besançon. Deux équipes de jeunes ont
            créé les contenus et le déroulé du jeu : 
          </CustomText>

          <Grid container style={{ padding: '2rem' }}>
            <Grid item xs={12} md={6}>
              <CustomTitle>Session 1 :</CustomTitle>
              <ul style={{ marginTop: 0, listStyle: 'none' }}>
                <li>
                  <CustomText>Floriane JOLIAT</CustomText>
                </li>
                <li>
                  <CustomText>Dereje GETU TADESSE</CustomText>
                </li>
                <li>
                  <CustomText>Anthony RUBY</CustomText>
                </li>
                <li>
                  <CustomText>Nahid ZEGAI</CustomText>
                </li>
                <li>
                  <CustomText>Carline GRICOLAT</CustomText>
                </li>
                <li>
                  <CustomText>Baptiste MORLET</CustomText>
                </li>
                <li>
                  <CustomText>Romain ECOIFIER</CustomText>
                </li>
                <li>
                  <CustomText>Nicolas THUROT</CustomText>
                </li>
                <li>
                  <CustomText>Bryton MAIGROT</CustomText>
                </li>
                <li>
                  <CustomText>Yanis JIMENEZ</CustomText>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomTitle>Session 2 :</CustomTitle>
              <ul style={{ marginTop: 0, listStyle: 'none' }}>
                <li>
                  <CustomText>Charles BRAS</CustomText>
                </li>
                <li>
                  <CustomText>Jade LATRACHE</CustomText>
                </li>
                <li>
                  <CustomText>Treyvis MOULIN</CustomText>
                </li>
                <li>
                  <CustomText>Moustapha KAMMOUN</CustomText>
                </li>
                <li>
                  <CustomText>Nadjati OMAR</CustomText>
                </li>
                <li>
                  <CustomText>Enzo FEIXEIRA</CustomText>
                </li>
                <li>
                  <CustomText>Alexi POGGI</CustomText>
                </li>
                <li>
                  <CustomText>Juan ARREDONDO</CustomText>
                </li>
                <li>
                  <CustomText>Victor LACOMBE</CustomText>
                </li>
                <li>
                  <CustomText>Alexandre ANGELOT</CustomText>
                </li>
                <li>
                  <CustomText>Luc FOUILLARD</CustomText>
                </li>
                <li>
                  <CustomText>Léo CHAUMONT </CustomText>
                </li>
              </ul>
            </Grid>
          </Grid>

          <Grid container style={{ pading: '2rem' }}>
            <Grid item xs={12}>
              <CustomTitle>Ils ont été accompagnés par :</CustomTitle>
              <ul style={{ marginTop: 0, listStyle: 'none' }}>
                <li>
                  <CustomText>Alix FAIVRE</CustomText>
                </li>
                <li>
                  <CustomText>Amélie DESIAUX</CustomText>
                </li>
                <li>
                  <CustomText>Luc MONNEY</CustomText>
                </li>
                <li>
                  <CustomText>Natacha ERNWEIN</CustomText>
                </li>
                <li>
                  <CustomText>Stéphanie COMMOT</CustomText>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ pading: '2rem' }}>
            <CustomTitle>Réalisation technique du jeu  : </CustomTitle>
            <CustomText>
              <strong>
                <a
                  href="https://www.debutant-e.pro/"
                  target="_blank"
                  rel="noreferrer"
                  alt="réalisateurs"
                >
                  DÉBUTANT-E ACCEPTÉ-E
                </a>
              </strong>
            </CustomText>
          </Grid>
          <Grid item xs={12} style={{ pading: '2rem' }}>
            <CustomTitle>Crédits images :</CustomTitle>
            <CustomText>
              <a
                href="https://fr.freepik.com/vecteurs/affaires"
                target="_blank"
                rel="noreferrer"
                alt="credit image"
              >
                Affaires vecteur créé par katemangostar - fr.freepik.com
              </a>
            </CustomText>
            <CustomText>
              <a
                href="https://fr.vecteezy.com/art-vectoriel/169250-jeune-homme-a-la-recherche-d-emploi"
                target="_blank"
                rel="noreferrer"
                alt="credit image"
              >
                Recherche emploi par Vecteezy
              </a>
            </CustomText>
            <CustomText>
              <a
                href="https://fr.vecteezy.com/vecteur-libre/entretien"
                target="_blank"
                rel="noreferrer"
                alt="credit image"
              >
                Entretien Vecteurs par Vecteezy
              </a>
            </CustomText>
            <CustomText>
              <a
                href="https://fr.freepik.com/vecteurs/ordinateur"
                target="_blank"
                rel="noreferrer"
                alt="credit image"
              >
                Ordinateur vecteur créé par pch.vector – fr.freepik.com
              </a>
            </CustomText>
            <CustomText>
              Icons made by{' '}
              <a
                href="https://www.freepik.com"
                target="_blank"
                rel="noreferrer"
                alt="credit image"
                title="Freepik"
              >
                Freepik
              </a>{' '}
              from{' '}
              <a
                href="https://www.flaticon.com/"
                target="_blank"
                rel="noreferrer"
                alt="credit image"
                title="Flaticon"
              >
                www.flaticon.com
              </a>
            </CustomText>
          </Grid>
          <Grid container justify="flex-end">
            <CustomButton handleNext={handleDialClose}>Fermer</CustomButton>
          </Grid>
        </ContentWrapper>
      </Dialog>
    </div>
  )
}

export default CustomFooterDialog
