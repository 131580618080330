import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const CustomTitle = (props) => {
  const { children, position } = props

  const classes = useStyles()

  return (
    <Typography align={position ? position : 'left'} className={classes.root}>
      {children}
    </Typography>
  )
}

export default CustomTitle

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '28px',
    lineHeight: '1.1',
    fontWeight: 700,
    color: theme.custom.titleColor,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))
