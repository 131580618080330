import { useEffect, useState } from 'react'
import { jobTips, schoolTips } from '../content'
import { topFunction } from '../../../../utils'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import LaunchIcon from '@material-ui/icons/Launch'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core'

import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomButton from '../../../../shared/CustomButton'
import phoneSkeleton from '../../../../images/smartphone_short.svg'

import {
  logoPoleEmploi,
  logoEmfor,
  logoCrij,
  logoML,
  logoLBF,
  logoIndeed,
  logoInterim,
  logoOnisep,
  logoCleor,
  phoneBg,
} from '../../../../images'

const Screen02 = (props) => {
  const { userInfo, handleScreenNext } = props

  const classes = useStyles()

  const [dialContent, setDialContent] = useState({})
  const [dialOpen, setDialOpen] = useState(false)
  const [items, setItems] = useState([])

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  useEffect(() => {
    if (userInfo.wish === 'Emploi') {
      setItems(jobTips)
    } else if (userInfo.wish === 'Formation') {
      setItems(schoolTips)
    }
  }, [userInfo])

  const handleIconClick = (item) => {
    setDialContent(item)
    setDialOpen(true)
  }

  const getRessourceLogo = (label) => {
    switch (label) {
      case 'pole-emploi':
        return logoPoleEmploi
      case 'interim':
        return logoInterim
      case 'indeed':
        return logoIndeed
      case 'onisep':
        return logoOnisep
      case 'mission-locale':
        return logoML
      case 'emfor':
        return logoEmfor
      case 'crij':
        return logoCrij
      case 'la-bonne-formation':
        return logoLBF
      case 'cleor':
        return logoCleor

      default:
        break
    }
  }

  return (
    <>
      <CustomRessourcesDialog
        open={dialOpen}
        setOpen={setDialOpen}
        dialContent={dialContent}
      />
      <div className={classes.root}>
        <div style={{ position: 'relative' }}>
          <div className={classes.phoneBG} />
          <div className={classes.phoneSkeleton}>
            <div>
              {items.map((item, idx) => (
                <div
                  key={idx}
                  className={classes.iconWrapper}
                  onClick={() => handleIconClick(item)}
                >
                  <img
                    className={`${classes.iconImg} ${item.size}`}
                    src={getRessourceLogo(item.id)}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Box mt={2}>
          <CustomButton handleNext={handleScreenNext}>
            J’ai lu et je continue
          </CustomButton>
        </Box>
      </div>
    </>
  )
}

export default Screen02

const CustomRessourcesDialog = (props) => {
  const { open, setOpen, dialContent } = props

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      className={classes.dialRoot}
      aria-labelledby="ressources-dialog"
      open={open}
    >
      <div className={classes.dialTopBar}>
        <button className={classes.dialBtn} onClick={() => setOpen(false)}>
          <CloseIcon className={classes.dialIcon} />
        </button>
      </div>
      <div className={classes.dialContent}>
        <ContentWrapper>
          <CustomTitle>{dialContent.label}</CustomTitle>
          <CustomText>
            <div dangerouslySetInnerHTML={{ __html: dialContent.definition }} />
          </CustomText>
          <CustomText>
            Se rendre sur{' '}
            <a href={dialContent.link} target="_blank" rel="noreferrer">
              {dialContent.linkName} <LaunchIcon className={classes.linkIcon} />
            </a>
          </CustomText>
        </ContentWrapper>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2rem',
    minHeight: '75vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  phoneBG: {
    position: 'absolute',
    content: "''",
    top: '70px',
    left: '0',
    width: '350px',
    height: '480px',
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6)100%),  url(${phoneBg})`,
    backgroundColor: '#ffffff',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundSize: '90%',
  },
  phoneSkeleton: {
    width: '350px',
    height: '647px',
    backgroundImage: `url(${phoneSkeleton})`,
    // backgroundColor: '#00000015',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    borderRadius: '70px',
    position: 'relative',
    '& > div': {
      padding: '105px 40px 0',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  iconWrapper: {
    width: '80px',
    height: '80px',
    borderRadius: '10px',
    marginTop: '1rem',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:nth-child(3n+2)': {
      marginLeft: '0.75rem',
      marginRight: '0.75rem',
    },
  },
  iconImg: {
    height: 'auto',
    '&.sm': {
      width: '60%',
    },
    '&.md': {
      width: '70%',
    },
    '&.xl': {
      width: '90%',
    },
  },
  dialRoot: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '700px',
      width: '100%',
    },
  },
  dialTopBar: {
    padding: '0.5rem 1rem',
    backgroundColor: '#191919',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialBtn: {
    width: '28px',
    height: '28px',
    backgroundColor: theme.palette.error.dark,
    border: 'none',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  dialIcon: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  linkIcon: {
    fontSize: '16px',
    marginBottom: '-3px',
  },
}))
