import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core'
import CustomButton from './CustomButton'

const CustomJobDialog = (props) => {
  const {
    dialogOpen,
    handleDialogClose,
    handleQuizzNext,
    currentQuestionIndex,
    questions,
    contentAnswer,
  } = props

  const classes = useStyles()

  const handleClickSubmitAnswer = () => {
    handleDialogClose()
    handleQuizzNext()
  }

  return (
    <div>
      <Dialog
        className={classes.root}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{contentAnswer}</DialogTitle>
        <DialogActions className={classes.btnGutter}>
          <CustomButton handleNext={handleClickSubmitAnswer}>
            {currentQuestionIndex === questions.length - 1
              ? 'Suivant'
              : 'Question suivante'}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CustomJobDialog

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.custom.paperBgColor,
    },
    '& .MuiDialogTitle-root': {
      color: theme.custom.titleColor,
      paddingTop: theme.spacing(3),
      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '1.1',
      },
    },
    '& .MuiDialogContentText-root': {
      color: theme.custom.textColor,
    },
  },
  btnGutter: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))
