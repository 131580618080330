import { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

import CustomFooterCreditsDialog from '../../shared/CustomFooterCreditsDialog'
import CustomFooterMentionsDialog from '../../shared/CustomFooterMentionsDialog'

const Footer = () => {
  const classes = useStyles()

  const [dialCreditsOpen, setCreditsDialOpen] = useState(false)
  const [dialMentionsOpen, setMentionsDialOpen] = useState(false)

  const handleCreditsDialClose = () => {
    setCreditsDialOpen(!dialCreditsOpen)
  }

  const handleMentionsDialClose = () => {
    setMentionsDialOpen(!dialMentionsOpen)
  }

  return (
    <div>
      <CustomFooterCreditsDialog
        handleDialClose={handleCreditsDialClose}
        dialOpen={dialCreditsOpen}
      />
      <CustomFooterMentionsDialog
        handleDialClose={handleMentionsDialClose}
        dialOpen={dialMentionsOpen}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Link onClick={() => setCreditsDialOpen(!dialCreditsOpen)}>
            <Typography className={classes.link}>Crédits</Typography>
          </Link>
        </Grid>
        <Grid item>
          <Link onClick={() => setMentionsDialOpen(!dialMentionsOpen)}>
            <Typography className={classes.link}>Mentions Légales</Typography>
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '10vh',
    backgroundColor: theme.custom.appBgColor,
  },
  link: {
    margin: '0 1rem',
    cursor: 'pointer',
  },
}))
