import { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core/'

import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import CustomButton from '../../../../shared/CustomButton'
import ContentWrapper from '../../../../shared/ContentWrapper'

import illustration from '../../../../images/accueil.jpg'
import Sponsor from '../../../home/Sponsor'

import { topFunction } from '../../../../utils'

const Screen01 = (props) => {
  const { handleScreenNext } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.appWrapper}>
      <CustomPaper className={classes.root}>
        <div className={classes.imgWrapper}>
          <img
            src={illustration}
            alt="illustration quizz"
            className={classes.img}
          />
        </div>
        <ContentWrapper>
          <CustomTitle position="center">
            Bonjour et bienvenue à toi, j’espère que tu as la forme et que tu es
            prêt(e) à relever des défis dans “Tut ’emploi”.
          </CustomTitle>

          <CustomText position="center">
            Dans ce jeu axé éducatif, tu seras dans la peau d’un(e) jeune qui a
            terminé ses études et qui a obtenu son diplôme. Tout au long du
            gameplay, tu vas apprendre à faire les bons choix qui t’aideront à
            atteindre ton but final.
          </CustomText>

          <CustomText position="center">
            Tu apprendras à faire un bon CV, une bonne lettre de motivation ce
            qui te permettra d’avoir toutes les clés en mains lors d’un
            entretien pour le réussir et obtenir un emploi ou une formation
            selon les choix que tu auras fait tout au long du jeu.
          </CustomText>

          <CustomText position="center">
            Comme tu l’auras compris, “Tut ’emploi” te permettra tout au long de
            ton parcours d’acquérir de l’expérience sur les étapes lors de la
            recherche d’une formation ou d’un emploi.
          </CustomText>
          <CustomButton handleNext={handleScreenNext}>
            commencer le jeu
          </CustomButton>
        </ContentWrapper>
      </CustomPaper>

      <Box mt={4}>
        <CustomPaper className={classes.root}>
          <ContentWrapper>
            <Sponsor />
          </ContentWrapper>
        </CustomPaper>
      </Box>
    </div>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  appWrapper: {
    padding: '3rem 0',
    minHeight: '75vh',
  },
  root: {
    backgroundColor: '#1F2937',
  },
  imgWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
}))
