export const workHeaderCoverLetter = [
  {
    image: 'good',
    isCorrect: 1,
  },
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'bad',
    isCorrect: 3,
  },
]

export const workBodyCoverLetter = [
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'good',
    isCorrect: 1,
  },
  {
    image: 'bad',
    isCorrect: 3,
  },
]
export const workFooterCoverLetter = [
  {
    image: 'bad',
    isCorrect: 3,
  },
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'good',
    isCorrect: 1,
  },
]

export const schoolHeaderCoverLetter = [
  {
    image: 'good',
    isCorrect: 1,
  },
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'bad',
    isCorrect: 3,
  },
]

export const schoolBodyCoverLetter = [
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'good',
    isCorrect: 1,
  },
  {
    image: 'bad',
    isCorrect: 3,
  },
]
export const schoolFooterCoverLetter = [
  {
    image: 'bad',
    isCorrect: 3,
  },
  {
    image: 'average',
    isCorrect: 2,
  },
  {
    image: 'good',
    isCorrect: 1,
  },
]

export const txt =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, deleniti, quae sint molestiae sit voluptatibus facilis quos soluta consectetur magni quia? Porro maxime labore officia.'
