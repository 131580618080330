import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Typography from '@material-ui/core/Typography'
import { useEffect, useState } from 'react'

import CustomJobDialog from '../../../../shared/CustomJobDialog'
import { jobQuizzData, schoolQuizzData } from '../../../../datas'
import CustomButton from '../../../../shared/CustomButton'
import CustomTitle from '../../../../shared/CustomTitle'

const Screen02 = (props) => {
  const { handleScreenNext, trainingQuizz, setTrainingQuizz, userInfo } = props

  const classes = useStyles()

  const [questions, setQuestions] = useState([])
  const [questionsShuffled, setQuestionsShuffled] = useState([])
  const [contentAnswer, setContentAnswer] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [answerScore, setAnswerScore] = useState(0)

  useEffect(() => {
    if (userInfo === 'Formation') {
      setQuestions(schoolQuizzData.questions)
    } else if (userInfo === 'Emploi') {
      setQuestions(jobQuizzData.questions)
    }
  }, [userInfo])

  useEffect(() => {
    const tempArr = questions
    tempArr.forEach(({ answers }) => (answers = shuffle(answers)))
    setQuestionsShuffled(tempArr)
  }, [questions])

  const handleQuizzNext = () => {
    if (trainingQuizz.currentQuestion === questions.length - 1) {
      setTrainingQuizz({
        ...trainingQuizz,
        currentQuestion: trainingQuizz.currentQuestion + 1,
        totalScore: trainingQuizz.totalScore + answerScore,
      })
      handleScreenNext()
    } else {
      setTrainingQuizz({
        ...trainingQuizz,
        currentQuestion: trainingQuizz.currentQuestion + 1,
        totalScore: trainingQuizz.totalScore + answerScore,
      })
    }
    setDisabledBtn(true)
    setDialogOpen(false)
  }

  const handleQuestionConfirm = (disabled) => {
    if (!disabled) {
      const answerList =
        questionsShuffled[trainingQuizz.currentQuestion].answers
      answerList.forEach((el) => (el.selected = false))
      setQuestionsShuffled([...questionsShuffled])
      setDialogOpen(true)
    }
  }

  const handleAnswerSelect = (idx, score, contentAnswer) => {
    const answerList = questionsShuffled[trainingQuizz.currentQuestion].answers
    const target = questionsShuffled[trainingQuizz.currentQuestion].answers[idx]

    answerList.forEach((el) => (el.selected = false))
    target.selected = true

    setAnswerScore(score)
    setContentAnswer(contentAnswer)
    setQuestionsShuffled([...questionsShuffled])
    setDisabledBtn(false)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const shuffle = (array) => {
    var currentIndex = array.length,
      randomIndex

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }

  const getPrefix = (idx) => {
    switch (idx) {
      case 0:
        return 'a'
      case 1:
        return 'b'
      case 2:
        return 'c'
      case 3:
        return 'd'

      default:
        return ''
    }
  }

  return (
    <>
      <CustomJobDialog
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        handleQuizzNext={handleQuizzNext}
        currentQuestionIndex={trainingQuizz.currentQuestion}
        questions={questions}
        contentAnswer={contentAnswer}
      />

      <div className={classes.root}>
        <div>
          <CustomTitle position="center">
            <span>{trainingQuizz.currentQuestion + 1}. </span>
            {questionsShuffled.length > 0 &&
              questionsShuffled[trainingQuizz.currentQuestion].label}
          </CustomTitle>
          <Grid item className={classes.responseWrapper}>
            <Grid container spacing={3} className={classes.reponseWrapper}>
              {questionsShuffled.length > 0 &&
                questionsShuffled[trainingQuizz.currentQuestion].answers.map(
                  ({ content, contentAnswer, selected, score }, idx) => (
                    <Grid item xs={12} key={idx}>
                      <Card
                        elevation={2}
                        className={`${classes.card} ${
                          selected ? 'selected' : null
                        }`}
                        onClick={() =>
                          handleAnswerSelect(idx, score, contentAnswer)
                        }
                      >
                        <CardActionArea disableRipple>
                          <CardContent>
                            <Typography
                              style={{ padding: '0.5rem' }}
                              variant="h6"
                              className={`${classes.quizzResponse} ${
                                selected ? 'selected' : null
                              }`}
                            >
                              <span>{getPrefix(idx)}.</span>
                              {content}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )
                )}
            </Grid>
            <Grid
              container
              justify="flex-end"
              className={classes.validAnswerBtnContainer}
            >
              <CustomButton
                handleNext={() => handleQuestionConfirm(disabledBtn)}
              >
                Valider
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  card: {
    border: '2px solid transparent',
    '&:hover': {
      border: '2px solid rgba(135,7,248,1)',
    },
    '&.selected': {
      background: theme.custom.answerColorHover,
      border: '0px solid transparent',
      color: theme.palette.common.white,
    },
  },
  validAnswerBtnContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  validAnswerBtn: {
    textTransform: 'capitalize',
  },
  responseWrapper: {
    padding: '0 1.5rem',
  },
  quizzTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(6),
  },
  quizzQuestion: {
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      marginRight: theme.spacing(3),
    },
  },
  quizzResponse: {
    '& > span': {
      textTransform: 'uppercase',
      fontWeight: 700,
      marginRight: theme.spacing(3),
    },
    '&.selected': {
      color: theme.palette.common.white,
    },
  },
  reponseWrapper: {
    marginTop: theme.spacing(3),
  },
  imgWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
}))
