import { createMuiTheme } from '@material-ui/core'

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#111827',
    },
  },
  typography: {
    fontFamily: "'Quicksand', sans-serif",
  },
  custom: {
    appBgColor: '#111827',
    paperBgColor: '#1F2937',
    titleColor: '#E5E7EB',
    textColor: '#FFFFFF',
    answerColorHover: '#0066C5',
  },
})

export default darkTheme
