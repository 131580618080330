import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import FsLightbox from 'fslightbox-react'
import CardMedia from '@material-ui/core/CardMedia'

import { workFooterGood, schoolFooterGood } from '../../../../images'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'

const Screen02 = (props) => {
  const { userWish, isCorrect, handleScreenNext, toggler, setToggler } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            {isCorrect === 1 && (
              <>
                <CustomTitle position="center">
                  Bravo, tu as choisi le pied de lettre idéal !
                </CustomTitle>
                <CustomText position="center">
                  C'est le meilleur choix car la conclusion se finit toujours
                  par une forme de politesse et éventuellement des
                  remerciements.
                </CustomText>
              </>
            )}
            {isCorrect === 2 && (
              <>
                <CustomTitle position="center">
                  Aïe, ce n'est pas la bonne réponse
                </CustomTitle>
                <CustomText position="center">
                  C’est la fête des fautes ici ! Il faudrait corriger un peu non
                  ? N’hésite pas à faire relire ta lettre de motivation par
                  d’autres personnes (Si possible bonne en orthographe, sinon
                  c’est inutile !)
                </CustomText>
                <CustomText position="center">
                  Voici le modèle idéal :
                </CustomText>
              </>
            )}
            {isCorrect === 3 && (
              <>
                <CustomTitle position="center">
                  Aïe, ce n'est pas la bonne réponse
                </CustomTitle>
                <CustomText position="center">
                  On met des smileys et on change la police d’écriture à la fin
                  pour plus de style ? Et puis quoi encore ! Nul, nul, nul !
                </CustomText>
                <CustomText position="center">
                  Voici le modèle idéal :
                </CustomText>
              </>
            )}

            <Grid
              container
              justify="center"
              style={{ marginBottom: '2rem' }}
            ></Grid>

            <Grid container justify="center" style={{ marginBottom: '2rem' }}>
              <Grid item xs={12}>
                <Card className={classes.itemContainer}>
                  {userWish === 'Emploi' && (
                    <CardMedia
                      className={classes.media}
                      image={workFooterGood}
                      title="curriculum-vitae"
                      onClick={() => setToggler(!toggler)}
                    />
                  )}
                  {userWish === 'Formation' && (
                    <CardMedia
                      className={classes.media}
                      image={schoolFooterGood}
                      title="curriculum-vitae"
                      onClick={() => setToggler(!toggler)}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
            {userWish === 'Emploi' && (
              <FsLightbox toggler={toggler} sources={[workFooterGood]} />
            )}
            {userWish === 'Formation' && (
              <FsLightbox toggler={toggler} sources={[schoolFooterGood]} />
            )}
            <CustomButton handleNext={handleScreenNext}>
              Passer à l'étape suivante
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  media: {
    border: '2px solid #00000025',
    cursor: 'zoom-in',
    height: '100%',
  },
  itemContainer: {
    width: '100%',
    height: '300px',
  },
}))
