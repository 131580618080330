import { orangeFondation, prefetBFC, regionBFC } from '../../images'
import { Typography } from '@material-ui/core'

const Sponsor = () => {
  return (
    <>
      <div style={{ marginTop: '4rem' }}>
        <Typography
          style={{
            fontSize: '32px',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Nos partenaires
        </Typography>
        <Typography
          style={{
            fontSize: '20px',
            marginTop: '1.5rem',
            textAlign: 'center',
          }}
        >
          Osez le numérique a été rendu possible grâce au soutien de :
        </Typography>
      </div>
      <div
        style={{
          minHeight: '175px',
          margin: '2rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
        }}
      >
        <a
          href="https://www.prefectures-regions.gouv.fr/bourgogne-franche-comte"
          alt="lien du prefet de bourgogne"
          rel="noreferrer"
          target="_blank"
        >
          <div
            style={{
              width: '140px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={prefetBFC}
              alt=""
            />
          </div>
        </a>
        <a
          href="https://www.bourgognefranchecomte.fr/"
          alt="lien de la région BFC"
          rel="noreferrer"
          target="_blank"
        >
          <div
            style={{
              width: '175px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={regionBFC}
              alt=""
            />
          </div>
        </a>
        <a
          href="https://www.fondationorange.com/"
          alt="lien de la fondation Orange"
          rel="noreferrer"
          target="_blank"
        >
          <div
            style={{
              width: '190px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={orangeFondation}
              alt=""
            />
          </div>
        </a>
      </div>
    </>
  )
}

export default Sponsor
