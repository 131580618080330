import Dialog from '@material-ui/core/Dialog'
import { Grid, makeStyles } from '@material-ui/core'

import CustomButton from './CustomButton'
import CustomTitle from './CustomTitle'
import CustomText from './CustomText'
import ContentWrapper from './ContentWrapper'

const CustomAgeDialog = (props) => {
  const { open, setOpen, errorTitle, errorText } = props

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ContentWrapper>
          <CustomTitle>{errorTitle}</CustomTitle>
          <CustomText id="alert-dialog-description">{errorText}</CustomText>
          <Grid container justify="flex-end">
            <CustomButton handleNext={handleClose}>continuer</CustomButton>
          </Grid>
        </ContentWrapper>
      </Dialog>
    </div>
  )
}

export default CustomAgeDialog

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: theme.custom.paperBgColor,
    },
    '& .MuiDialogTitle-root': {
      color: theme.custom.titleColor,
      paddingTop: theme.spacing(3),
      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: '22px',
        lineHeight: '1.1',
      },
    },
    '& .MuiDialogContentText-root': {
      color: theme.custom.textColor,
    },
  },
  btnGutter: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))
