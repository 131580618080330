import { Box, makeStyles } from '@material-ui/core'

import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'
import { timerQuizz } from '../../../../images'
import { useEffect } from 'react'
import { topFunction } from '../../../../utils'

const Screen01 = (props) => {
  const { handleScreenNext } = props

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.root}>
      <div style={{ padding: '2rem 0' }}>
        <CustomPaper>
          <ContentWrapper>
            <Box mt={2}>
              <CustomTitle position="center">
                Quizz contre la montre
              </CustomTitle>
            </Box>
            <div style={{ padding: '1rem 0' }}>
              <div className={classes.imgWrapper}>
                <img
                  src={timerQuizz}
                  alt="Illustration du quizz"
                  className={classes.img}
                />
              </div>
            </div>
            <CustomText position="center">
              Pour cet entretien le temps est compté, tu auras 30 secondes
              pour répondre. Si tu ne réponds pas ou que tu as choisi une
              mauvaise réponse tu perdras un point d'embauche.
            </CustomText>
            <CustomButton handleNext={handleScreenNext}>
              Prêt pour l’entretien
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen01

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  imgWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
}))
