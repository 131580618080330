import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Screen01 from './screens/Screen01'
import Screen02 from './screens/Screen02'

const Step05 = (props) => {
  const { screen, setScreen, userInfo, handleStepNext } = props

  const [interviewDate, setInterviewDate] = useState('')

  const handleScreenNext = () => {
    setScreen((prevActiveStep) => prevActiveStep + 1)
    sessionStorage.setItem('currentScreen', screen + 1)
  }

  const handleScreenBack = () => {
    setScreen((prevActiveStep) => prevActiveStep - 1)
    sessionStorage.setItem('currentScreen', screen - 1)
  }

  const handleScreenReset = () => {
    setScreen(0)
    sessionStorage.setItem('currentScreen', 0)
    handleStepNext()
  }

  return (
    <>
      <Container>
        <Step05Stepper
          handleScreenNext={handleScreenNext}
          handleScreenBack={handleScreenBack}
          screen={screen}
        >
          <Step05Screen>
            <Grid item>
              <Screen01
                handleScreenNext={handleScreenNext}
                setInterviewDate={setInterviewDate}
                userInfo={userInfo}
              />
            </Grid>
          </Step05Screen>
          <Step05Screen>
            <Grid item>
              <Screen02
                handleScreenReset={handleScreenReset}
                interviewDate={interviewDate}
                userInfo={userInfo}
              />
            </Grid>
          </Step05Screen>
        </Step05Stepper>
      </Container>
    </>
  )
}

const Step05Stepper = (props) => {
  const { children, screen } = props
  const childrenArray = React.Children.toArray(children)

  const currentChild = childrenArray[screen]

  return <>{currentChild}</>
}

const Step05Screen = ({ children }) => {
  return <>{children}</>
}

export default Step05
