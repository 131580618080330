import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { topFunction } from '../../../../utils'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import FsLightbox from 'fslightbox-react'
import CardMedia from '@material-ui/core/CardMedia'

import { workHeaderGood, schoolHeaderGood } from '../../../../images'
import ContentWrapper from '../../../../shared/ContentWrapper'
import CustomButton from '../../../../shared/CustomButton'
import CustomPaper from '../../../../shared/CustomPaper'
import CustomText from '../../../../shared/CustomText'
import CustomTitle from '../../../../shared/CustomTitle'

const Screen02 = (props) => {
  const { isCorrect, handleScreenNext, userWish, toggler, setToggler } = props

  console.log(isCorrect)

  const classes = useStyles()

  useEffect(() => {
    setTimeout(() => topFunction(), 150)
  }, [])

  return (
    <div className={classes.root}>
      <div>
        <CustomPaper>
          <ContentWrapper>
            {isCorrect === 1 && (
              <>
                <CustomTitle position="center">
                  Bravo, tu as choisi l'entête de lettre idéal !
                </CustomTitle>
                <CustomText position="center">
                  En effet c'est le choix idéal car les éléments sont disposés
                  de la bonne manière. Les coordonnées personnelles sont placées
                  en haut à gauche et celle de l'entreprise plus bas à droite.
                </CustomText>
                <CustomText position="center">
                  L'objet est bien positionné à gauche et mis en valeur (en
                  gras). Bravo !
                </CustomText>
              </>
            )}
            {isCorrect === 2 && (
              <>
                <CustomTitle position="center">
                  Aïe, ce n'est pas la bonne réponse
                </CustomTitle>
                <CustomText position="center">
                  Ce n’est pas le meilleur choix… Il y a bien des informations,
                  mais il en manque et le format n’est pas le bon !
                </CustomText>
                <CustomText position="center">
                  Voici le modèle idéal :
                </CustomText>
              </>
            )}
            {isCorrect === 3 && (
              <>
                <CustomTitle position="center">
                  Aïe, ce n'est pas la bonne réponse
                </CustomTitle>
                <CustomText position="center">
                  Il n’y a rien qui va dans cet entête ! Pas de nom, pas
                  d’adresse, mail hors sujet, il n’y a rien à garder ! C’est
                  honteux !
                </CustomText>
                <CustomText position="center">
                  Voici le modèle idéal :
                </CustomText>
              </>
            )}
            <Grid
              container
              justify="center"
              style={{ marginBottom: '2rem' }}
            ></Grid>
            <Grid container justify="center" style={{ marginBottom: '2rem' }}>
              <Grid item xs={12}>
                <Card className={classes.itemContainer}>
                  {userWish === 'Emploi' && (
                    <CardMedia
                      className={classes.media}
                      image={workHeaderGood}
                      title="curriculum-vitae"
                      onClick={() => setToggler(!toggler)}
                    />
                  )}
                  {userWish === 'Formation' && (
                    <CardMedia
                      className={classes.media}
                      image={schoolHeaderGood}
                      title="curriculum-vitae"
                      onClick={() => setToggler(!toggler)}
                    />
                  )}
                </Card>
              </Grid>
            </Grid>
            {userWish === 'Emploi' && (
              <FsLightbox toggler={toggler} sources={[workHeaderGood]} />
            )}
            {userWish === 'Formation' && (
              <FsLightbox toggler={toggler} sources={[schoolHeaderGood]} />
            )}
            <CustomButton handleNext={handleScreenNext}>
              Passer à l'étape suivante
            </CustomButton>
          </ContentWrapper>
        </CustomPaper>
      </div>
    </div>
  )
}

export default Screen02

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  media: {
    border: '2px solid #00000025',
    cursor: 'zoom-in',
    height: '100%',
  },
  itemContainer: {
    width: '100%',
    height: '300px',
  },
}))
